import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Blog.css';
import blogImage1 from '../../assets/Images/blog-image-1.png';
import blogImage2 from '../../assets/Images/blog-image-2.png';
import blogImage3 from '../../assets/Images/blog-image-3.png';
import pdfIcon from '../../assets/Icons/pdfIcon.png';
import BlogThumbnail from "./BlogThumbnail";

import ElectricalBROCHURE from '../../assets/PDF folder/BALCO BROCHURE Electrical.pdf';
import CorporateBROCHURE from '../../assets/PDF folder/BALCO CORPORATE BROCHURE.pdf';
import WaterPipesBROCHURE from '../../assets/PDF folder/BALCO WATER PIPES BROCHURE.pdf';


export default function Blog() {

    const handleDownload1 = () => {
        const pdfUrl = ElectricalBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO BROCHURE Electrical.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleDownload2 = () => {
        const pdfUrl = CorporateBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO CORPORATE BROCHURE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleDownload3 = () => {
        const pdfUrl = WaterPipesBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO WATER PIPES BROCHURE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <div>
            <Navbar />
            {/* blog title */}
            <div className="container">
                <div className="blog-div">
                    <h1 className="blog-title title">News & Blog</h1>
                </div>
            </div>
            {/* blog news */}
            <div style={{ background: '#ECECEC' }}>
                <div className="container py-5">
                    <div>
                        <p className="blog-small-text small-text">News & Media</p>
                        <h4 className="blog-heading heading">News & Articles</h4>
                        <p className="blog-text text">Family months lasted simple set nature of vulgar him. Picture for attempt.</p>
                    </div>
                    <BlogThumbnail/>
                </div>

                <div className="container pb-5">
                    <div className="" style={{ background: "#D9D9D9", padding: "15px" }}>
                        <div>
                            <iframe width="100%" height="443px" src="https://www.youtube-nocookie.com/embed/D_qpVpb6cEg?si=hlRcMEJdfaH4_0su" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                    <hr />
                    <div className="bg-white p-3">
                        <h2 className="blog-title title">SECURING YOUR HEALTH: WHY OPTING FOR BALCO HEALTH-FRIENDLY WATER PIPES IS A WATER SAFETY GAME CHANGER</h2>
                        <p className="blog-heading heading text-center">Meenakshi  / March 2, 2023</p>
                    </div>
                    <div style={{ background: "#D9D9D9", padding: "15px" }}>
                        <div className="w-100">
                            <img src={blogImage1} alt="blogImage1" width={'100%'} />
                        </div>
                        <div>
                            <h2 className="blog-title title">Unveiling BALCO's Health-Friendly Water Pipes: Features and Benefits</h2>
                            <p className="blog-text text">Teritatis et quasi architecto. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo invent.</p>
                            <p className="blog-text text">Teritatis et quasi architecto. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo invent. Sed ut perspiciatis unde omnis iste natus error sit. Taccusantium dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo invent. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="blog-text text">Have you ever given it a thought, why we humans are often referred to as walking, talking bags of water? Quite a bit hilarious yet scientifically accurate, wouldn't you agree? That's because our bodies contain a whopping 60% of water. Now, let that sink in for a while. Yes, just like that great cup of joe or that lip-smacking chocolate cake, the very essence of our existence depends on good ol' H2O too.
                                        But, here's the real bummer. The quality of water we consume is only as good as the medium it is transported through. And what's that? Precisely, the pipes! The conduits of life, if you might. Which sadly, in most cases, aren't as well-intended as our dear H2O. So, our doorway to health might also be a highway to hell if it isn't BALCO, or as I'd love to call it Bountiful Aqua Life-giving Conduits. What's BALCO, you wonder? Pull up a chair dear reader, we're just getting started.</p>
                                    <p className="blog-text text">I. Background on the Hazards of Low-Quality Water Supply Systems
                                        If there's one thing that's worse than no water, it's contaminated water. Imagine your water supply—an essential part of daily life—as a Trojan horse, carrying unforeseen health hazards right into your homes. The culprit? Subpar water pipes.
                                        Unmasking the Dangers: Understanding the Risks Associated with Substandard Water Piping Systems
                                        Substandard water pipes—oh, where do I start!—can harbor countless contaminants. This can range from harmful bacteria to heavy metals; all of them pose grave health issues. Decaying pipes often leach lead—a toxic element—into the water supply, contaminating it. We may not be adding lead, but if the pipes can't</p>
                                </div>
                                <div className="col-md-6">
                                    <img src={blogImage2} alt="blogImage2" width={'100%'} />
                                </div>
                            </div>
                            <div>
                                <p className="blog-text text mb-3">safely deliver the water, it's like sending out an open invitation to water-borne diseases.</p>
                                <p className="blog-text text">The Long-Term Health Consequences of Exposure to Contaminated Water Long-term exposure to contaminated water leads to a host of health issues. From neurological disorders in children—talk about stealing their futures—to an increased risk of hypertension and renal dysfunctions in adults, the list goes on and on. No one wants to play Russian roulette with their health, right?</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={blogImage3} alt="blogImage3" width={'100%'} />
                                </div>
                                <div className="col-md-6">
                                    <div className="my-4 mx-lg-4 px-lg-5 container">
                                        <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload1} style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>Download Electrical Brochure <br />
                                                <span className="contact-text small-text" style={{ color: "black" }}>Click here to download .PDF</span></span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                        </button>
                                        <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload2} style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>Download Corporate Brochure<br />
                                                <span className="contact-text small-text" style={{ color: "black" }}>Click here to download .PDF</span></span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                        </button>
                                        <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload3} style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>Download Water Pipes Brochure <br />
                                                <span className="contact-text small-text" style={{ color: "black" }}>Click here to download .PDF</span></span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}