import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';
import pdfIcon from '../../assets/Icons/pdfIcon.png';
import { useState, useEffect } from "react";
import axios from 'axios';

export default function MaterialDocuments() {

    const [materialContractstotheIssue, setMaterialContractstotheIssue] = useState([]);
    const [materialDocuments, setMaterialDocuments] = useState([]);
    const [ipoResolution, setIPOResolution] = useState([]);
    const [annualReport, setAnnualReport] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [consent, setConsent] = useState([]);


    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-material-documents');

            // api data come like this
            // File_Name: "sample file"
            // Type_Of_Document: "Material Contracts to the Issue"
            // Upload_File: "/media/Material_Documents/Malayalam_Brochure__revised.pdf"

            const data = response.data;

            // Filter the data based on the Type_Of_Document and set the state accordingly
            const materialContracts = data.filter(doc => doc.Type_Of_Document === "Material Contracts to the Issue");
            const materialDocs = data.filter(doc => doc.Type_Of_Document === "Material Documents");
            const ipoRes = data.filter(doc => doc.Type_Of_Document === "IPO Resolution");
            const annualReps = data.filter(doc => doc.Type_Of_Document === "Annual Report");
            const docs = data.filter(doc => doc.Type_Of_Document === "Documents");
            const consents = data.filter(doc => doc.Type_Of_Document === "Consent");

            setMaterialContractstotheIssue(materialContracts);
            setMaterialDocuments(materialDocs);
            setIPOResolution(ipoRes);
            setAnnualReport(annualReps);
            setDocuments(docs);
            setConsent(consents);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleDownload = (pdfUrl, fileName) => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Material Documents</h2>
                    <div>
                        
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                                A. Material Contracts to the Issue
                            </div>
                                {materialContractstotheIssue.length > 0 ? (
                                    materialContractstotheIssue.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                                B. Material Documents
                            </div>
                                {materialDocuments.length > 0 ? (
                                    materialDocuments.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                             IPO Resolution
                            </div>
                                {ipoResolution.length > 0 ? (
                                    ipoResolution.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className="my-3">
                       
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                                Annual Report
                            </div>
                                {annualReport.length > 0 ? (
                                    annualReport.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                       
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                                Documents
                            </div>
                                {documents.length > 0 ? (
                                    documents.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                            <div className="investor-thead mb-3" style={{height:"50px", borderRadius:"31px", display:'flex', alignItems:"center", padding: "2px 10px"}}>
                                Consent
                            </div>
                                {consent.length > 0 ? (
                                    consent.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.File_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.Upload_File, `${item.File_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}


// B. Material Documents
// IPO Resolution
// Annual Report
// Documents
// Consent