import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import flexiblePipes from '../../assets/Images/flexibles.png';
import ProductBanner from "./ProductBanners";

export default function Flexible () {
    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                    <div className="d-md-flex justify-content-center aline-item-center align-items-center py-5">
                        <div className="">
                            <h2 className="product-title title text-white">Flexibility Redefined: BALCO Flexible Conduits</h2>
                        </div>
                        <div className="">
                            <img src={flexiblePipes} alt="flexiblePipes" width={"100%"}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6">
                        <h2 className="product-heading heading">BALCO Flexible Conduits</h2>
                        <p className="product-text text">BALCO Flexible Conduits offer a range of versatile solutions designed to meet your electrical installation requirements with ease and efficiency. Our conduits are engineered to provide flexibility and durability, making them ideal for a wide range of applications in residential, commercial, and industrial settings.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="m-4" style={{backgroundColor: "#D9D9D9"}}>
                            <img src={flexiblePipes} alt="flexiblePipes" width={"100%"}/>
                        </div>
                    </div>
                </div>
                <hr className="black"/>
                <div className=" my-4">
                <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Flexibility: Easily bend and maneuver the conduits to fit complex installation layouts.</li>
                                <li>Durability: Constructed with high-quality materials for long-lasting performance and reliability.</li>
                                <li>Versatility: Suitable for various electrical wiring needs, including protection and routing of wires and cables.</li>
                                <li>Ease of Installation: Designed for quick and hassle-free installation, saving time and effort.</li>
                            </ul>
                </div>
                </div>
                {/* product heading and color */}
                <ProductBanner/>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Marking</li>
                                <li>Checking of Dimensions</li>
                                <li>Resistance to Heat</li>
                                <li>Resistance to Burn</li>
                                <li>Moisture Absorption Test</li>
                                <li>Resistance to Chemical Action</li>
                                <li>Copper Test</li>
                                <li>Resistance to Oil</li>
                                <li>Resistance to Impact</li>
                                <li>Electrical Strength</li>
                                <li>Insulation Resistance</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div className="flex-grow-1">
                                    <img src={flexiblePipes} alt="allProductsImage" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}