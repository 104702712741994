import Navbar from "./navbar/Navbar";
import Footer from "./navbar/Footer";

export default function TermsConditions() {

    return (
        <div>
            <Navbar />
            <div className="container py-5">
                <h2 className="pb-5">TERMS AND CONDITIONS</h2>

                <h4>Welcome to Solve Plastic Products Ltd! </h4>

                <p>
                    These terms and conditions outline the rules and regulations for the use of
                    Solve Plastic Products Ltd located at www.balcopipes.com commands its privacy
                    policy.
                </p>
                <p>
                    By accessing this website we assume you accept these terms and conditions. Do not
                    continue to use Solve Plastic Products Ltd if you do not agree to take all of the terms
                    and conditions stated on this page. Our Terms and Conditions were created with the
                    help of the Terms And Conditions Generator.
                </p>
                <p>
                    The following terminology applies to these Terms and Conditions, Privacy Statement
                    and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you,
                    the person log on this website and compliant to the Company’s terms and conditions.
                    “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
                    “Parties”, or “Us”, refers to both the Client and ourselves.
                </p>
                <p>
                    All terms refer to the offer, acceptance and consideration of payment necessary to
                    undertake the process of our assistance to the Client in the most appropriate manner
                    for the express purpose of meeting the Client’s needs in respect of the provision of
                    the Company’s stated services, in accordance with and subject to, prevailing law of
                    India. Any use of the above terminology or other words in the singular, plural,
                    capitalization and/or he/she or they, are taken as interchangeable and therefore as
                    referring to the same.
                </p>
            </div>
            <Footer />
        </div>
    )
}