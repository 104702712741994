import './Header.css';
import FaceBook from '../../assets/Icons/social_facebook_circle.png';
import LinkedIn from '../../assets/Icons/social_linkedin_circle.png';
import Youtube from '../../assets/Icons/youtube_circle.png';
import Instagram from '../../assets/Icons/instagram_circle.png';
import Twitter from '../../assets/Icons/social_twitter_circle.png';
import logo from '../../assets/Balco Logo.png';
import plane from '../../assets/Icons/plane.png';
import { useNavigate } from 'react-router-dom';


export default function Footer() {
    const navigate = useNavigate();


    return (
        <div className='footer py-3'>
            <div className="container">
                <div className='d-flex justify-content-between aline-item-center align-items-center'>
                    <div className='footer-title'>Let’s Build Together!</div>
                    <img src={logo} width={150} height={50} alt="logo" />
                </div>
                <hr style={{color:"#fff"}}/>
                <div className='my-3'>
                        <div className="row gap-8 justify-between text-white">
                            <div className="col-sm-6 col-md-4 opacity-80 my-2">
                                <div className="gap-4">
                                    <h4 className="fw-bold">Let's Get Started</h4>
                                    <div className='my-3'>
                                        <button className='btn footer-btn'>Get News & Update
                                        <img src={plane} alt="plane" className='ms-5' />
                                        </button>

                                        <div className='d-flex my-2'>
                                            <input type="radio" id='terms_policies' className='me-2'/>
                                            <span className='agree-text'>I agree to all your terms & policies</span>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <a href="https://www.facebook.com/balcopvcpipes/" target="_blank" rel="noopener noreferrer" className='mx-2' ><img src={FaceBook} alt="FaceBook" /></a>
                                        <a href="https://www.youtube.com/channel/UC1yGWVTNMK46wn2M5GZPRrA" target="_blank" rel="noopener noreferrer" className='mx-2' ><img src={Youtube} alt="Youtube" /></a>
                                        <a href="https://www.instagram.com/balcopvcpipes/" target="_blank" rel="noopener noreferrer" className='mx-2' ><img src={Instagram} alt="Instagram" /></a>
                                        <a href="https://www.twitter.com/balcopvcpipes" target="_blank" rel="noopener noreferrer" className='mx-2' ><img src={Twitter} alt="Twitter" /></a>
                                        <a href="https://www.linkedin.com/company/balcopipes" target="_blank" rel="noopener noreferrer" className='mx-2' ><img src={LinkedIn} alt="LinkedIn" /></a>
                                    </div>
                                    <div className='mt-2'>
                                        <p className='footer-heading'>Right Choice for Decades</p>
                                    </div>
                                </div>
                            </div>
                            <hr className='white-footer'/>
                            <div className="col-sm-6 col-md-2 opacity-80 my-2">
                                <div className="gap-4">
                                    <h6 className='footer-heading'>Quick Link</h6>
                                    <div className=''>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/about")}> About </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/product/pvc-electricals")}> Product </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/investors")}> Investors </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/blog")}> Blog </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/privacy&terms")}> Privacy </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }} onClick={() => navigate("/terms&conditions")}> Terms & Conditions </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", cursor:"pointer" }}> <a style={{textDecoration:"none", color:"white"}} target='_blank' href="https://forms.zohopublic.in/solveplastic/form/FeedbackForm/formperma/LjPEnz6z1X_tY84wpCFBg2284YG7PQvP87CNUGPtbg4">Feedback</a> </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='white-footer'/>
                            <div className="col-sm-6 col-md-3 opacity-80 my-2">
                                <div className="gap-4">
                                    <h6 className='footer-heading'>Contact Info</h6>
                                    <div>
                                        <p className='footer-subHeading' style={{ marginBottom: "10px" }}> Reg Office Address - </p>
                                        <span className='footer-address'>Solve Plastic Products Ltd. </span>
                                        <span className='footer-address'>DOOR NO XIII/690/ABC</span>
                                        <span className='footer-address'> THOLICODE PUNALUR, KOLLAM,</span>
                                        <span className='footer-address'> Kerala, India, 691333</span>
                                    </div>
                                    <div className='mt-2'>
                                        <p className='footer-subHeading' style={{ marginBottom: "10px" }}> Corporate office address - </p>
                                        <span className='footer-address'>Solve Plastic Products Ltd. </span>
                                        <span className='footer-address'>2nd Floor, BALCO Building,</span>
                                        <span className='footer-address'> XXIX/456, Powerhouse Ward, Tholicode PO, Punalur, Kollam,</span>
                                        <span className='footer-address'> Kerala, 691333</span>
                                    </div>
                                    <div className='footer-number my-3'>
                                        +91 62 35 35 00 00
                                    </div>
                                </div>
                            </div>
                            <hr className='white-footer'/>
                            <div className="col-sm-6 col-md-3 opacity-80 my-2">
                                <div className="gap-4">
                                    <h6 className='footer-heading'>Products</h6>
                                    <div className=''>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/pvc-electricals")}> RIGID PVC ELECTRIC CONDUITS </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/pvc-electricals-fitting")}> RIGID PVC ELECTRIC CONDUIT FITTINGS </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/pvc-water-pipes")}> UPVC PIPES </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/pvc-water-pipes-fitting")}> UPVC PIPES FITTING </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/flexible-hoses")}> FLEXIBLE CONDUITS</p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/solvent")}> SOLVENT CEMENT </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/green-hoses")}> GARDEN & SUCTION  HOSES </p>
                                        <p className='footer-subHeading' style={{ marginBottom: "0px", fontSize:"14px", cursor:"pointer"  }} onClick={() => navigate("/product/water-tanks")}> WATER TANK </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <hr style={{color:"#fff"}}/>
                <div className='text-end text-white'>
                    <p className='footer-copyright'>Copyright © 2023 <span style={{color:"#FFBF43"}}>BALCO Pipes</span>. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}