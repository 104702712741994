import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';
import { useState, useEffect } from "react";
import axios from 'axios';

export default function BoardOfDirectors() {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-board-of-directors');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">DETAILS OF BOARD OF DIRECTORS & KMP OF SOLVE PLASTIC PRODUCTS LTD.</h2>

                    <div style={{overflowX:"auto"}}>
                    <table className="invertors-table">
                    <thead className="investor-thead mb-5">
                        <tr>
                        <th>Sr. No.</th>
                        <th>DIN/PAN</th>
                        <th>Name of Director</th>
                        <th>Designation</th>
                        </tr>
                    </thead>
                    <tbody className="investor-tbody" style={{backgroundColor:"#fff"}}>
                        {data.length > 0 && data.map((item, i) => {
                            return(
                            <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.PAN}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}