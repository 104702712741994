import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import PVCElectricImage from '../../assets/Images/ePVC pipes.png';
import PVCProduct1 from '../../assets/Images/PVCElectric.png';
import pdfIcon from '../../assets/Icons/pdfIcon.png';
import conduitColor from '../../assets/Products/Conduit color.png';
import productpipes1 from '../../assets/Products/elec pvc/elec pvc product 1.png';
import productpipes2 from '../../assets/Products/elec pvc/elec pvc product 2.png';
import productpipes3 from '../../assets/Products/elec pvc/elec pvc product 3.png';
import productpipes4 from '../../assets/Products/elec pvc/elec pvc product 4.png';
import productpipes5 from '../../assets/Products/elec pvc/elec pvc product 5.png';
import productpipes6 from '../../assets/Products/elec pvc/elec pvc product 6.png';
import Checkbox from '../../assets/Icons/checkbox.png';
import CheckboxNone from '../../assets/Icons/checkbox-none.png';
// import brand1 from "../../assets/Images/brand1.png";
import brand2 from "../../assets/Images/brand2.png";
import brand3 from "../../assets/Images/brand3.png";
import brand4 from "../../assets/Images/brand4.png";
import brand5 from "../../assets/Images/brand5.png";
import brand6 from "../../assets/Images/brand6.png";
import brand7 from "../../assets/Images/brand7.png";
import brand8 from "../../assets/Images/brand8.png";
import brand9 from "../../assets/Images/brand9.png";
import elecBanner from "../../assets/Products/elec pvc/elec-pvc-banner.png";
import pvcbannerslider1 from "../../assets/Products/elec pvc/elec-pvc-banner-slider1.png";
import pvcbannerslider2 from "../../assets/Products/elec pvc/elec-pvc-banner-slider2.png";
import pvcbannerslider3 from "../../assets/Products/elec pvc/elec-pvc-banner-slider3.png";
import pvcbannerslider4 from "../../assets/Products/elec pvc/elec-pvc-banner-slider4.png";

import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ElectricalBROCHURE from '../../assets/PDF folder/BALCO BROCHURE Electrical.pdf';
import CorporateBROCHURE from '../../assets/PDF folder/BALCO CORPORATE BROCHURE.pdf';


export default function PVCElectric () {

    const checkboxIcon = <img src={Checkbox} alt="Checkbox" width={20} height={20} />;
    const checkboxNoneIcon = <img src={CheckboxNone} alt="CheckboxNone" width={20} height={20} />;

    const specification = [
        
        {nominalSize: '16MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"13.7", max:"14"}, wallThickness: {min:"1", max:"1.15"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '16MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"13", max:"13.3"}, wallThickness: {min:"1.35", max:"1.5"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '16MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"12.2", max:"12.5"}, wallThickness: {min:"1.75", max:"1.9"}, outSideDiameter:"", socketing:{min:"", max:""}},

        {nominalSize: '20MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"17.4", max:"17.7"}, wallThickness: {min:"1.15", max:"1.3"}, outSideDiameter:"22.58", socketing:{min:"20.23", max:"20.28"}},
        {nominalSize: '20MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"16.9", max:"17.2"}, wallThickness: {min:"1.4", max:"1.55"}, outSideDiameter:"22.93", socketing:{min:"20.27", max:"20.3"}},
        {nominalSize: '20MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.3', insideDiameter: {min:"15.8", max:"16.1"}, wallThickness: {min:"1.95", max:"2.1"}, outSideDiameter:"23.9", socketing:{min:"20.18", max:"20.22"}},

        {nominalSize: '25MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"22.1", max:"22.5"}, wallThickness: {min:"1.25", max:"1.45"}, outSideDiameter:"27.8", socketing:{min:"25.29", max:"25.32"}},
        {nominalSize: '25MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"21.4", max:"21.8"}, wallThickness: {min:"1.6", max:"1.8"}, outSideDiameter:"28.33", socketing:{min:"25.1", max:"25.32"}},
        {nominalSize: '25MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"20.6", max:"21"}, wallThickness: {min:"2", max:"2.2"}, outSideDiameter:"29.4", socketing:{min:"25.15", max:"25.34"}},

        {nominalSize: '32MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"28.6", max:"29"}, wallThickness: {min:"1.5", max:"1.7"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '32MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"27.8", max:"28.2"}, wallThickness: {min:"1.9", max:"2.1"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '32MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"26.6", max:"27"}, wallThickness: {min:"2.5", max:"2.7"}, outSideDiameter:"", socketing:{min:"", max:""}},

        {nominalSize: '40MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"35.8", max:"36.2"}, wallThickness: {min:"1.9", max:"2.1"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '40MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"35.4", max:"35.8"}, wallThickness: {min:"2.1", max:"2.3"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '40MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.4', insideDiameter: {min:"34.4", max:"34.8"}, wallThickness: {min:"2.6", max:"2.8"}, outSideDiameter:"", socketing:{min:"", max:""}},

        {nominalSize: '50MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.5', insideDiameter: {min:"45.1", max:"45.6"}, wallThickness: {min:"2.2", max:"2.45"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '50MM MEDIUM \n BALCO RPVC EC/ISI/B ', tolerance: '-0.5', insideDiameter: {min:"44.3", max:"44.8"}, wallThickness: {min:"2.6", max:"2.85"}, outSideDiameter:"", socketing:{min:"", max:""}},
        {nominalSize: '50MM HEAVY \n BALCO RPVC EC/ISI/B ', tolerance: '-0.5', insideDiameter: {min:"43.2", max:"43.7"}, wallThickness: {min:"3.15", max:"3.4"}, outSideDiameter:"", socketing:{min:"", max:""}},

        {nominalSize: '63MM LIGHT \n BALCO RPVC EC/ISI/B ', tolerance: '-0.6', insideDiameter: {min:"57", max:"57.6"}, wallThickness: {min:"2.7", max:"3"}, outSideDiameter:"", socketing:{min:"", max:""}},
    ];

    const [slidesToShow, setSlidesToShow] = useState(5);
    const [screenWidth, setScreenWidth] = useState();

  useEffect(() => {
    const handleResize = () => {
      // Adjust slidesToShow based on screen size
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 992) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(5);
      }
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        accessibility: false,
        centerMode: true,
        // variableWidth: true,
        className:"brand-slider",
        // centerPadding: "10px",
      };
    var settingsProduct = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

    const HomeSlider = [
        // {image: brand1},
        {image: brand2},
        {image: brand3},
        {image: brand4},
        {image: brand5},
        {image: brand6},
        {image: brand7},
        {image: brand8},
        {image: brand9},
    ]

    const productImage = [
        {image: productpipes1},
        {image: productpipes2},
        {image: productpipes3},
        {image: productpipes6},
        {image: productpipes4},
        {image: productpipes5},
    ]

    const bannerSlider = [
        {image: pvcbannerslider1},
        {image: pvcbannerslider2},
        {image: pvcbannerslider3},
        {image: pvcbannerslider4},
    ]

    const handleDownload1 = () => {
        const pdfUrl = ElectricalBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO BROCHURE Electrical.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleDownload2 = () => {
        const pdfUrl = CorporateBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO CORPORATE BROCHURE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                        <div className="d-md-flex justify-content-center align-items-center py-5">
                        <div className="">
                            <h2 className="product-title title text-white">BALCO® Unmatched Durability and Safety in Rigid PVC Electric Conduits</h2>
                        </div>
                        <div className="w-lg-50">
                            <img src={PVCElectricImage} alt="PVCElectricImage" width={"100%"}/>
                        </div>
                        </div>
                    </div>    
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6 d-flex my-2">
                        <div className="flew-grow-1">
                            <h2 className="product-heading heading">Rigid PVC Electric conduits</h2>
                            <p className="product-text text">BALCO ® rigid electric conduits are manufactured in virgin PVC which makes it strong to withstand any amount of pressure and heat. The properties in BALCO ® rigid PVC electric conduits are so unique that it is non-flammable in nature, also the flexibility of our electrical conduit is an added advantage by helping in reducing the additional expenses of using fittings. Safety is the ruling factor when it comes to electrical wiring, fittings like bend, elbow and fixtures. BALCO ® rigid electric conduits stand out in the market for our exceptional quality and durability.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex my-2">
                        <div className="flew-grow-1">
                            <img src={elecBanner} alt="elecBanner" width={'100%'}/>
                        </div>
                    </div>
                </div>
                {/* <div className="row my-4 text-center">
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                </div> */}
                <div className="my-4">
                    <h4 className="product-heading heading text-center fw-bold" style={{color:"#004F87"}}>Approvals</h4>
                    <Slider {...settings}>
                        {HomeSlider.map((item, i) => {
                            return(
                                <div key={i} className="w-auto">
                                    <img src={item.image} width={"100%"} style={{padding:"0px 5px"}} alt={`home slider ${i}`}/>
                                 </div>
                                )
                        })}
                    </Slider>
                </div>
                <div className="row my-4">
                    <div className="col-lg-6 my-2">
                        <h4 className="product-subheading subheading">Please note that in BALCO Electrical Conduits, each color on the pipe signifies its class:</h4>
                        <ul>
                            <li className="product-text text"> 
                            <span style={{fontWeight:"bolder"}}>LMS (Light): </span> 
                            Represented by a specific color, our LMS conduits are designed for lighter electrical loads and applications.
                            </li>
                            <li className="product-text text"> 
                            <span style={{fontWeight:"bolder"}}>MMS (Medium): </span> 
                            Another color denotes our MMS conduits, which are suitable for medium-level electrical requirements.
                            </li>
                            <li className="product-text text"> 
                            <span style={{fontWeight:"bolder"}}>HMS (Heavy): </span> 
                            The color associated with HMS conduits indicates their capacity to handle heavy-duty electrical needs and installations.
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 my-2">
                        <img src={conduitColor} alt="conduitColor" width={"100%"}/>
                    </div>
                    <p className="product-text text">Before choosing BALCO Conduits, it's crucial to confirm the type of requirement you have to ensure compatibility and optimal performance. Our high-quality electrical conduits are built to deliver outstanding performance and durability, guaranteeing a lifetime of reliable service.</p>
                </div>
                </div>
                {/* product heading and color */}
                <div className="" style={{background: "#004F87"}}>
                    <div className="text-center">
                        <div className="m-5 mb-4 py-5">
                            <h2 className="product-title title text-white text-center" style={{fontWeight:"700"}}>BALCO Conduits </h2>
                        </div>
                        {/* <p className="product-subheading subheading text-white text-center">Available color options</p>
                        <div className="d-flex flex-wrap" style={{justifyContent:"center"}}>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#FAF7E2'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#3076B6'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#656565'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#B3FFB1'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#9D2D2D'}}></div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* product items */}
                {/* <div className="container my-5">
                    <div className="row">
                        {new Array(12).fill(null).map((item, i) => {
                            return(
                                <div className="col-md-4 col-sm-6 my-3">
                                    <div style={{border:"2px solid #AEAEAE"}}>
                                        <div className="my-4">
                                            <img src={PVCProduct1} alt="PVCProduct1" width={'100%'}/>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h2 className="product-heading heading text-center" >BALCO Conduits</h2>
                                        <p className="product-text text text-center" style={{lineHeight:"0.4"}}>20mm ISI Pipes green</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-6">
                            <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Non-conductor of electricity</li>
                                <li>High mechanical strength for covered and open applications</li>
                                <li>Non-destructive in nature and resistant to chemical and galvanic erosion.</li>
                                <li>First of its kind to have ISI certification in Kerala.</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4" >
                            <div className="">
                                <button className="btn btn-gold d-flex my-1 w-100" style={{justifyContent:"space-between", alignItems:"center"}}>
                                    <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Brochure <br />
                                    <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                    <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                </button>
                            </div>
                            <div className="">
                                <button className="btn btn-gold d-flex my-1 w-100" style={{justifyContent:"space-between", alignItems:"center"}}>
                                    <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Brochure <br />
                                    <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                    <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* product size */}
                {/* <div className="container">
                    <div className="my-4">
                    <h4 className="product-heading heading fw-bolder">BALCO RPVC CONDUITS- ISI-IS  :9537 Part 3 <br /> COLOR BLACK / IVORY / GREY</h4> 
                    <div className="row" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div className="col-lg-6 my-2">
                        <div className="row">
                            <div className="col-6"> <div className="product-scale-box fw-bolder">Size</div> </div>
                            <div className="col-6"> <div className="product-scale-box fw-bolder">Standard Pack</div></div>
                        </div>
                        <div className="row">
                            <div className="col-6"><div className="product-scale-box">16 mm</div></div><div className="col-6"><div className="product-scale-box">100</div></div>
                            <div className="col-6"><div className="product-scale-box">20 mm</div></div><div className="col-6"><div className="product-scale-box">100</div></div>
                            <div className="col-6"><div className="product-scale-box">25 mm</div></div><div className="col-6"><div className="product-scale-box">50</div></div>
                            <div className="col-6"><div className="product-scale-box">32 mm</div></div><div className="col-6"><div className="product-scale-box">25</div></div>
                            <div className="col-6"><div className="product-scale-box">40 mm</div></div><div className="col-6"><div className="product-scale-box">20</div></div>
                            <div className="col-6"><div className="product-scale-box">50 mm</div></div><div className="col-6"><div className="product-scale-box">15</div></div>
                            <div className="col-6"><div className="product-scale-box">63 mm</div></div><div className="col-6"><div className="product-scale-box">10</div></div>
                        </div>
                    </div>
                    <div className="col-lg-6 my-2">
                        <img src={productpipes1} alt="productpipes1" width={"100%"}/>
                    </div>
                </div>
                    </div>
                </div> */}
                {/* image slider */}
                <div className="container">
                    <div className="my-1">
                                {/* <div className="w-100" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"400px", backgroundColor: "#D9D9D9"}}> */}
                                <Slider {...settingsProduct}>
                                    {bannerSlider.map((item, i) => {
                                        return(
                                            <div key={i} className="w-auto">
                                                <img src={item.image} width={"100%"} style={{padding:"0px 5px"}} alt={`home slider ${i}`}/>
                                            </div>
                                            )
                                    })}
                                </Slider>
                                {/* </div> */}
                    </div>
                </div>
                {/* table content */}
                <div className="container my-5">
                    <div style={{border:"7px solid #004779", padding:"15px 30px",fontFamily:"Inter"}}>
                        <h2 className="product-title title" style={{fontFamily:"Inter", textAlign: "center"}}>
                            ELECTRICAL CONDUIT: BALCO RPVC CONDUITS- ISI-IS:9537 PART3 <br />
                            RPVC CONDUITS COLOR BLACK / IVORY / GREY
                        </h2>
                    </div>
                    <div className="row my-4">
                            <div className="col-md-6 my-2" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <table className="product-table">
                                    <thead>
                                        <tr><td className="product-heading heading" style={{color: "#004F87", border:"5px solid #004779"}} colSpan="4">Range of Conduits</td></tr>
                                        <tr  className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                            <td>Range</td>
                                            <td>LMS</td>
                                            <td>MMS</td>
                                            <td>HMS</td>
                                        </tr>
                                    </thead>
                                    <tbody className="product-text text fw-bold">
                                        <tr><td>16</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>20</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>25</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>32</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>40</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>50</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                        <tr><td>63</td><td>{checkboxIcon}</td><td>{checkboxNoneIcon}</td><td>{checkboxNoneIcon}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 my-2" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <table className="product-table">
                                    <thead>
                                        <tr><td className="product-heading heading" style={{color: "#004F87", border:"5px solid #004779"}} colSpan="4">PVC Conduits Packing quantity per bundle</td></tr>
                                        <tr  className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                            <td>Range</td>
                                            <td>Standard <br /> pack (Mtr)</td>
                                            <td>Length <br /> (No.s)</td>
                                        </tr>
                                    </thead>
                                    <tbody className="product-text text">
                                        <tr><td className="fw-bold">16</td><td>300</td><td>100</td></tr>
                                        <tr><td className="fw-bold">20</td><td>300</td><td>100</td></tr>
                                        <tr><td className="fw-bold">25</td><td>150</td><td>50</td></tr>
                                        <tr><td className="fw-bold">32</td><td>75</td><td>25</td></tr>
                                        <tr><td className="fw-bold">40</td><td>60</td><td>20</td></tr>
                                        <tr><td className="fw-bold">50</td><td>30</td><td>10</td></tr>
                                        <tr><td className="fw-bold">63</td><td>30</td><td>10</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    <hr className="black"/>
                    <div className="my-4">
                        <div>
                        <h2 className="product-title title" style={{fontFamily:"Inter", textAlign: "center"}}>
                            BALCO RPVC CONDUITS- NON ISI <br />
                            RPVC CONDUITS COLOR BLACK / IVORY / GREY
                        </h2>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-6 my-2" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <table className="product-table">
                                    <thead>
                                        <tr><td className="product-heading heading" style={{color: "#004F87", border:"5px solid #004779"}} colSpan="4">Range of Conduits</td></tr>
                                        <tr  className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                            <td>Range</td>
                                            <td>LMS</td>
                                            <td>MMS</td>
                                            <td>HMS</td>
                                        </tr>
                                    </thead>
                                    <tbody className="product-text text fw-bold">
                                        <tr><td>19</td><td>{checkboxNoneIcon}</td><td>{checkboxIcon}</td><td>{checkboxIcon}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 my-2" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <table className="product-table">
                                    <thead>
                                        <tr><td className="product-heading heading" style={{color: "#004F87", border:"5px solid #004779"}} colSpan="4">PVC Conduits Packing quantity per bundle</td></tr>
                                        <tr  className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                            <td>Range</td>
                                            <td>Standard <br /> pack (Mtr)</td>
                                            <td>Length <br /> (No.s)</td>
                                        </tr>
                                    </thead>
                                    <tbody className="product-text text">
                                        <tr><td className="fw-bold">19</td><td>300</td><td>100</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr className="black"/>
                    <div className="my-4">
                        <h4 className="text-center">PVC CONDUIT SPECIFICATIONS:</h4>
                    <div className="row">
                        <div className="col-lg-9 my-2">
                            <div className="my-2">
                                <div className="ps-sm-5" style={{border:"7px solid #004779", padding:"15px 30px",fontFamily:"Inter"}}>
                                    <h2 className="product-title title" style={{fontFamily:"Inter", textAlign: "center"}}>
                                        Technical Specification as per IS 9537 Part -3
                                        <hr style={{borderTop:"3px solid #004779", opacity:"1"}}/>
                                        Make Name: BALCO®
                                    </h2>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <table className="product-table">
                                    <thead>
                                        <tr className="product-text text fw-bold">
                                            <td  style={{backgroundColor:"#004779", color:"white"}}>Nominal Size(mm)</td>
                                            <td>Tolerance on OD(mm)</td>
                                            <td colSpan={2}>Inside Diameter(mm)</td>
                                            <td colSpan={2}>Wall Thickness(mm)</td>
                                            <td>Outside Diameter for Socket End(mm)</td>
                                            <td colSpan={2}>Socketing ID for Socket End(mm)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specification.map((item, index) => {
                                            return(
                                                index === 0 ?
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td rowSpan={2} style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td rowSpan={2}>{item.tolerance}</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td rowSpan={2}>{item.outSideDiameter}</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                </tr>
                                                <tr className="product-text small-text fw-bold my-2">
                                                    <td>{item.insideDiameter.min}</td>
                                                    <td>{item.insideDiameter.max}</td>
                                                    <td>{item.wallThickness.min}</td>
                                                    <td>{item.wallThickness.max}</td>
                                                    <td>{item.socketing.min}</td>
                                                    <td>{item.socketing.max}</td>
                                                </tr>
                                                </>
                                                :
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td>{item.tolerance}</td>
                                                    <td>{item.insideDiameter.min}</td>
                                                    <td>{item.insideDiameter.max}</td>
                                                    <td>{item.wallThickness.min}</td>
                                                    <td>{item.wallThickness.max}</td>
                                                    <td>{item.outSideDiameter}</td>
                                                    <td>{item.socketing.min}</td>
                                                    <td>{item.socketing.max}</td>
                                                </tr>
                                                {(index + 1) % 3 === 0 && <tr key={`empty_${index}`}><td style={{border:"none"}} colSpan="9">&nbsp;</td></tr>}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-3 my-2" style={{backgroundColor:"#E8E8E8"}}>
                            <Slider {...settingsProduct} className={`${screenWidth > 992 && 'd-none'}`}>
                                {productImage.map((item, i) => {
                                    return(
                                        <div key={i} className="w-auto">
                                            <img src={item.image} width={"100%"} alt={`home slider ${i}`}/>
                                        </div>
                                        )
                                })}
                            </Slider>
                            <div className={`${screenWidth < 992 && 'd-none'}`}>
                            <Slider {...settingsProduct}>
                                {productImage.slice(0, 2).map((item, i) => (
                                    <div key={i} className="w-auto">
                                        <img src={item.image} width={"100%"} alt={`home slider ${i + 2}`}/>
                                    </div>
                                ))}
                            </Slider>
                            <Slider {...settingsProduct}>
                                {productImage.slice(2, 4).map((item, i) => (
                                        <div key={i} className="w-auto">
                                            <img src={item.image} width={"100%"} alt={`home slider ${i + 2}`}/>
                                        </div>
                                ))}
                            </Slider>
                            <Slider {...settingsProduct}>
                                {productImage.slice(4).map((item, i) => (
                                        <div key={i} className="w-auto">
                                            <img src={item.image} width={"100%"} alt={`home slider ${i + 2}`}/>
                                        </div>
                                ))}
                            </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="my-2">
                        <h4  className="product-subheading subheading fw-bold">**Pipe availability:</h4>
                        <p className="product-text text">16 MM (Light, Medium, Heavy): We will be offering plain ended pipes only.</p>
                        <p className="product-text text">20 MM and 25 MM: We will offer both plain ended and socketed pipes.</p>
                        <p className="product-text text">32 MM, 40 MM, 50 MM, 63 MM: Production for these sizes can be carried out against specific orders.</p>
                    </div>
                    </div>
                </div>
                {/* Advance & features */}
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 my-2">
                            <div className="p-2" style={{background: "#F3F3F3"}}>
                                <h4 className="product-heading heading" style={{color: "#004F87"}}>ADVANTAGES OF BALCO CONDUITS</h4>
                                <ul className="product-text text">
                                    <li>We deliver the highest quality Electric Conduits and Fittings.</li>
                                    <li>Our products are highly durable, lightweight, monotonic and non-corrosive. </li>
                                    <li>Easy installation and long lasting nature of PVC conduits avoid periodical maintenance. </li>
                                    <li>Balco PVC conduits can be bend at any angle thus to avoid expenses for fittings like bends, elbows etc.*</li>
                                    <li>Our conduits are manufactured from high impact resistant PVC which is specially formulated to meet superior standards. </li>
                                    <li>Our conduits are the first of its kind in Kerala having both ISI and ISO certification.</li>
                                    <li>Our conduits  have been approved by Kerala PWD, CPWD, Railway, BSNL etc.</li>
                                    <li>(*16mm,20mm & 25mm conduits can be bend using spring, thus limiting the use of added fittings.)</li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 my-2">
                        <div className="p-2" style={{background: "#F3F3F3"}}>
                                <h4 className="product-heading heading" style={{color: "#004F87"}}>SPECIAL FEATURES</h4>
                                <ul className="product-text text">
                                    <li>Non-conductor of electricity.</li>
                                    <li>High mechanical quality for covered and open applications.</li>
                                    <li>Non-destructive in nature and resistant to chemical and galvanic erosion.</li>
                                    <li>Doesn't bolster ignition and are self-smothering.</li>
                                    <li>Smooth internal surface for simple wiring; adaptability for long span twisting.</li>
                                    <li>Flame retardant and low smoke in nature. </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* balco brochure */}
                <div className="container my-5" style={{background: "#EEEEEE"}}>
                    <div className="row">
                    <div className="col-md-8">
                        <div className="p-3">
                        <h6  className="product-text text fw-bold">Features</h6>
                    {/* <ul className="product-text text">
                        <li>Non-conductor of electricity</li>
                        <li>High mechanical strength for covered and open applications</li>
                        <li>Non-destructive in nature and resistant to chemical and galvanic erosion.</li>
                        <li>First of its kind to have ISI certification in Kerala.</li>
                    </ul> */}
                    <p className="product-text text">BALCO Conduits are made with high-quality materials. Long-lasting, with no use of plasticizers, made with virgin materials, and have low fillers. which is the main reason BALCO has become the professional's favorite choice. BALCO is expanding its reach across all of India and exporting extensively. To learn more about BALCO Electrical Conduits and their technical details, download our brochure for comprehensive information.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-3">
                            <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload1} style={{justifyContent:"space-between", alignItems:"center"}}>
                                <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Brochure Electical<br />
                                <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                            </button>
                            <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload2} style={{justifyContent:"space-between", alignItems:"center"}}>
                                <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Corporate Brochure <br />
                                <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                            </button>
                        </div>
                    </div>
                    </div>
                   
                </div>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                            <li>Construction</li>
                            <li>Marking</li>
                            <li>Durability of Marking</li>
                            <li>Dimensions</li>
                            <li>Uniformity of wall thickness</li>
                            <li>Bending test(at room temperature)</li>
                            <li>Compression Test</li>
                            <li>Collapse Test</li>
                            <li>Resistance of Heat</li>
                            <li>Resistance to Burning</li>
                            <li>Electrical Characteristics</li>
                            <li>Bending Test (at low temperature)</li>
                            <li>Impact Test</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <img src={PVCProduct1} alt="PVCProduct1" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <style>
        {`
                .slick-next {
                    right: 15px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #dba63a;
                }
                .slick-prev {
                    left: 15px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #dba63a;
                }
                `}
      </style>
        </div>
    )
}