import pipesImage from "../../assets/Products/Conduits.png";
import fittingImage from "../../assets/Products/Fittings.png";
import solventImage from "../../assets/Products/Solvent.png";
import WaterImage from "../../assets/Products/water tank.png";

export default function ProductBanner () {
    return(
        <div className="" style={{background: "#004F87"}}>
                    <div className="container text-center">
                        <div className="row py-4">
                            <div className="col-lg-3 col-md-3 col-sm-6 my-2 d-flex"> 
                                <div className="flex-grow-1">
                                    <div style={{width:"auto", backgroundColor: "#D9D9D9", margin:"10px auto"}}>
                                        <img src={pipesImage} alt="pipesImage" width={'100%'}/>
                                    </div>
                                    <p className="product-subheading subheading text-white">Conduits</p>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 my-2 d-flex"> 
                                <div className="flex-grow-1">
                                    <div style={{width:"auto", backgroundColor: "#D9D9D9", margin:"10px auto"}}>
                                        <img src={fittingImage} alt="fittingImage" width={'100%'}/>
                                    </div>
                                    <p className="product-subheading subheading text-white">Fittings</p>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 my-2 d-flex"> 
                                <div className="flex-grow-1">
                                    <div style={{width:"auto", backgroundColor: "#D9D9D9", margin:"10px auto"}}>
                                        <img src={solventImage} alt="solventImage" width={'100%'}/> 
                                    </div>
                                    <p className="product-subheading subheading text-white">Solvent Cement</p>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 my-2 d-flex"> 
                                <div className="flex-grow-1">
                                    <div style={{width:"auto", backgroundColor: "#D9D9D9", margin:"10px auto"}}>
                                        <img src={WaterImage} alt="WaterImage" width={'100%'}/>    
                                    </div>
                                    <p className="product-subheading subheading text-white">Water Tanks</p>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
    )
}