import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import pvcpipes from '../../assets/Images/pvc-water.png';

import brand1 from "../../assets/Products/upvc/UPVC Brand - 1.png";
import brand2 from "../../assets/Products/upvc/UPVC Brand - 2.jpg";
import brand3 from "../../assets/Products/upvc/UPVC Brand - 3.png";

import ProductBanner from "./ProductBanners";
import allProductsImage from "../../assets/Image content/dense-construction-site 1.png";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function PVCPipes () {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };

    const brandSlider = [
        {image: brand1},
        {image: brand2},
        {image: brand3},
    ]

    const specification = [
        
        {nominalSize: '20mm 10kg/cm²', meanOD: {min:"20.00", max:"20.30"}, odAtAnyPoint: {min:"19.50", max:"20.50"}, wellThickness:{min:"1.10", max:"1.50"}},
        {nominalSize: '20mm 12.5kg/cm²', meanOD: {min:"20.00", max:"20.30"}, odAtAnyPoint: {min:"19.50", max:"20.50"}, wellThickness:{min:"1.40", max:"1.80"}},

        {nominalSize: '25mm 8kg/cm²', meanOD: {min:"25.00", max:"25.30"}, odAtAnyPoint: {min:"24.50", max:"25.50"}, wellThickness:{min:"1.20", max:"1.60"}},
        {nominalSize: '25mm 10kg/cm²', meanOD: {min:"25.00", max:"25.30"}, odAtAnyPoint: {min:"24.50", max:"25.50"}, wellThickness:{min:"1.40", max:"1.60"}},
        {nominalSize: '25mm12.5kg/cm²', meanOD: {min:"25.00", max:"25.30"}, odAtAnyPoint: {min:"24.50", max:"25.50"}, wellThickness:{min:"1.70", max:"2.10"}},

        {nominalSize: '32mm8kg/cm²', meanOD: {min:"32.00", max:"32.30"}, odAtAnyPoint: {min:"31.50", max:"32.50"}, wellThickness:{min:"1.50", max:"1.90"}},
        {nominalSize: '32mm10kg/cm²', meanOD: {min:"32.00", max:"32.30"}, odAtAnyPoint: {min:"31.50", max:"20.50"}, wellThickness:{min:"1.80", max:"2.20"}},
        {nominalSize: '32mm12.5kg/cm²', meanOD: {min:"32.00", max:"32.30"}, odAtAnyPoint: {min:"31.50", max:"25.50"}, wellThickness:{min:"2.20", max:"2.70"}},

        {nominalSize: '40mm6kg/cm²', meanOD: {min:"40.00", max:"40.30"}, odAtAnyPoint: {min:"39.50", max:"40.50"}, wellThickness:{min:"1.40", max:"1.80"}},
        {nominalSize: '40mm8kg/cm²', meanOD: {min:"40.00", max:"40.30"}, odAtAnyPoint: {min:"39.50", max:"40.50"}, wellThickness:{min:"1.80", max:"2.20"}},
        {nominalSize: '40mm10kg/cm²', meanOD: {min:"40.00", max:"40.30"}, odAtAnyPoint: {min:"39.50", max:"40.50"}, wellThickness:{min:"2.20", max:"2.70"}},
        {nominalSize: '40mm12kg/cm²', meanOD: {min:"40.00", max:"40.30"}, odAtAnyPoint: {min:"39.50", max:"40.50"}, wellThickness:{min:"2.80", max:"3.30"}},

        {nominalSize: '50mm6kg/cm²', meanOD: {min:"50.00", max:"50.30"}, odAtAnyPoint: {min:"49.40", max:"50.60"}, wellThickness:{min:"1.70", max:"2.10"}},
        {nominalSize: '50mm8kg/cm²', meanOD: {min:"50.00", max:"50.30"}, odAtAnyPoint: {min:"49.40", max:"50.60"}, wellThickness:{min:"2.30", max:"2.80"}},
        {nominalSize: '50mm10kg/cm²', meanOD: {min:"50.00", max:"50.30"}, odAtAnyPoint: {min:"49.40", max:"50.60"}, wellThickness:{min:"2.80", max:"3.30"}},
        {nominalSize: '50mm12.5kg/cm²', meanOD: {min:"50.00", max:"50.30"}, odAtAnyPoint: {min:"49.40", max:"50.60"}, wellThickness:{min:"3.40", max:"4.00"}},

        {nominalSize: '63mm4kg/cm²', meanOD: {min:"63.00", max:"63.30"}, odAtAnyPoint: {min:"62.20", max:"63.80"}, wellThickness:{min:"1.50", max:"1.90"}},
        {nominalSize: '63mm6kg/cm²', meanOD: {min:"63.00", max:"63.30"}, odAtAnyPoint: {min:"62.20", max:"63.80"}, wellThickness:{min:"2.20", max:"2.70"}},
        {nominalSize: '63mm8kg/cm²', meanOD: {min:"63.00", max:"63.30"}, odAtAnyPoint: {min:"62.20", max:"63.80"}, wellThickness:{min:"2.80", max:"3.30"}},
        {nominalSize: '63mm10kg/cm²', meanOD: {min:"63.00", max:"63.30"}, odAtAnyPoint: {min:"62.20", max:"63.80"}, wellThickness:{min:"3.50", max:"4.10"}},
        {nominalSize: '63mm12.5kg/cm²', meanOD: {min:"63.00", max:"63.30"}, odAtAnyPoint: {min:"62.20", max:"63.80"}, wellThickness:{min:"4.30", max:"5.00"}},

        {nominalSize: '75mm4kg/cm²', meanOD: {min:"75.00", max:"75.30"}, odAtAnyPoint: {min:"74.10", max:"75.90"}, wellThickness:{min:"1.80", max:"2.20"}},
        {nominalSize: '75mm6kg/cm²', meanOD: {min:"75.00", max:"75.30"}, odAtAnyPoint: {min:"74.10", max:"75.90"}, wellThickness:{min:"2.60", max:"3.10"}},
        {nominalSize: '75mm8kg/cm²', meanOD: {min:"75.00", max:"75.30"}, odAtAnyPoint: {min:"74.10", max:"75.90"}, wellThickness:{min:"3.40", max:"4.00"}},
        {nominalSize: '75mm10kg/cm²', meanOD: {min:"75.00", max:"75.30"}, odAtAnyPoint: {min:"74.10", max:"75.90"}, wellThickness:{min:"4.20", max:"4.90"}},
        {nominalSize: '75mm12.5kg/cm²', meanOD: {min:"75.00", max:"75.30"}, odAtAnyPoint: {min:"74.10", max:"75.90"}, wellThickness:{min:"5.10", max:"5.90"}},

        {nominalSize: '90mm2.5kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"1.80", max:"2.20"}},
        {nominalSize: '90mm4kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"2.10", max:"2.60"}},
        {nominalSize: '90mm6kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"3.10", max:"3.70"}},
        {nominalSize: '90mm8kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"4.10", max:"4.60"}},
        {nominalSize: '90mm10kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"5.00", max:"5.70"}},
        {nominalSize: '90mm12.5kg/cm²', meanOD: {min:"90.00", max:"90.30"}, odAtAnyPoint: {min:"88.90", max:"91.10"}, wellThickness:{min:"6.10", max:"7.10"}},
    ];

    const specification2 = [
        

        {nominalSize: '110mm2.5kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"1.60", max:"2.00"}},
        {nominalSize: '110mm4kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"2.50", max:"3.00"}},
        {nominalSize: '110mm6kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"3.70", max:"4.00"}},
        {nominalSize: '110mm8kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"4.90", max:"5.60"}},
        {nominalSize: '110mm10kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"6.10", max:"7.10"}},
        {nominalSize: '110mm12.5kg/cm²', meanOD: {min:"110.00", max:"110.40"}, odAtAnyPoint: {min:"108.80", max:"111.40"}, wellThickness:{min:"7.50", max:"8.70"}},

        {nominalSize: '140mm2.5kg/cm²', meanOD: {min:"140.00", max:"140.50"}, odAtAnyPoint: {min:"138.30", max:"141.70"}, wellThickness:{min:"2.00", max:"2.40"}},
        {nominalSize: '140mm4kg/cm²', meanOD: {min:"140.00", max:"140.50"}, odAtAnyPoint: {min:"138.30", max:"141.70"}, wellThickness:{min:"3.20", max:"3.80"}},
        {nominalSize: '140mm6kg/cm²', meanOD: {min:"140.00", max:"140.50"}, odAtAnyPoint: {min:"138.30", max:"141.70"}, wellThickness:{min:"4.80", max:"5.50"}},
        
        {nominalSize: '160mm2.5kg/cm²', meanOD: {min:"160.00", max:"160.50"}, odAtAnyPoint: {min:"158.00", max:"162.00"}, wellThickness:{min:"2.30", max:"2.80"}},
        {nominalSize: '160mm4kg/cm²', meanOD: {min:"160.00", max:"160.50"}, odAtAnyPoint: {min:"158.00", max:"162.00"}, wellThickness:{min:"3.70", max:"4.30"}},
        {nominalSize: '160mm6kg/cm²', meanOD: {min:"160.00", max:"160.50"}, odAtAnyPoint: {min:"158.00", max:"162.00"}, wellThickness:{min:"5.40", max:"6.20"}},

        {nominalSize: '180mm2.5kg/cm²', meanOD: {min:"180.00", max:"180.60"}, odAtAnyPoint: {min:"177.80", max:"182.20"}, wellThickness:{min:"2.60", max:"3.10"}},
        {nominalSize: '180mm4kg/cm²', meanOD: {min:"180.00", max:"180.60"}, odAtAnyPoint: {min:"177.80", max:"182.20"}, wellThickness:{min:"4.20", max:"4.90"}},
        {nominalSize: '180mm6kg/cm²', meanOD: {min:"180.00", max:"180.60"}, odAtAnyPoint: {min:"177.80", max:"182.20"}, wellThickness:{min:"6.10", max:"7.10"}},

        {nominalSize: '200mm2.5kg/cm²', meanOD: {min:"200.00", max:"200.60"}, odAtAnyPoint: {min:"197.60", max:"202.40"}, wellThickness:{min:"2.90", max:"3.40"}},
        {nominalSize: '200mm4kg/cm²', meanOD: {min:"200.00", max:"200.60"}, odAtAnyPoint: {min:"197.60", max:"202.40"}, wellThickness:{min:"4.60", max:"5.30"}},
        {nominalSize: '200mm6kg/cm²', meanOD: {min:"200.00", max:"200.60"}, odAtAnyPoint: {min:"197.60", max:"202.40"}, wellThickness:{min:"6.80", max:"7.90"}},

        
        {nominalSize: '20 mm Plumbing', meanOD: {min:"20.00", max:"20.30"}, odAtAnyPoint: {min:"19.50", max:"20.50"}, wellThickness:{min:"2.80", max:"3.30"}},
        {nominalSize: '25 mm Plumbing', meanOD: {min:"25.00", max:"25.30"}, odAtAnyPoint: {min:"24.50", max:"25.50"}, wellThickness:{min:"2.90", max:"3.40"}},
        {nominalSize: '32 mm Plumbing', meanOD: {min:"32.00", max:"32.30"}, odAtAnyPoint: {min:"31.50", max:"32.50"}, wellThickness:{min:"3.40", max:"3.90"}},
        {nominalSize: '40 mm Plumbing', meanOD: {min:"40.00", max:"40.30"}, odAtAnyPoint: {min:"39.50", max:"40.50"}, wellThickness:{min:"3.60", max:"4.20"}},
        {nominalSize: '50 mm Plumbing', meanOD: {min:"50.00", max:"50.30"}, odAtAnyPoint: {min:"49.40", max:"50.60"}, wellThickness:{min:"3.70", max:"4.30"}},
    ]

    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                        <div className="d-md-flex justify-content-center align-items-center py-5">
                        <div className="">
                            <h2 className="product-title title text-white">BALCO®: The Ultimate Choice for Safe and Reliable Water Distribution</h2>
                        </div>
                        <div className="w-lg-50">
                            <img src={pvcpipes} alt="pvcpipes" width={"100%"}/>
                        </div>
                        </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6">
                        <h2 className="product-heading heading">UPVC PIPES</h2>
                        <p className="product-text text">BALCO ® water pipes are made of unplasticized poly vinyl chloride (uPVC) making it ideal for cold water plumbing application. Made by the finest quality graded virgin PVC with IS 4985 + ISO 9000 : 2015 certification, BALCO ® pipes help in smooth water management with alage and UV resistant properties which also has high thermal stability and is corrosion resistant. Predominantly BALCO ® stands as a leading brand promoting safer distribution of water, thus earning the name of the safest potable water pipe with the lowest and negligible amount of lead content. We aim to build a healthier community by improving our products by constant R&D to nullify the lead content in the coming years.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="m-4">
                            <div style={{backgroundColor: "#D9D9D9", width:"100%"}}>
                                <img src={pvcpipes} alt="pvcpipes" width={"100%"}/>    
                             </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* certificates */}
                <div className="container">
                <div className="row my-4 text-center">
                    {/* <div className="col-4 my-2">
                        <div className="d-flex justify-content-center align-item-center">
                            <img src={brand1} alt="brand1" width={'100%'} style={{padding: "0px 20px"}}/>
                        </div>
                    </div>
                    <div className="col-4 my-2">
                        <div className="d-flex justify-content-center align-item-center">
                            <img src={brand2} alt="brand2" width={'100%'} style={{padding: "0px 20px"}}/>
                        </div>
                    </div>
                    <div className="col-4 my-2">
                        <div className="d-flex justify-content-center align-item-center">
                            <img src={brand3} alt="brand3" width={'100%'} style={{padding: "0px 20px"}}/>
                        </div>
                    </div> */}

                    <Slider {...settings}>
                        {brandSlider.map((item, i) => {
                            return(
                                <div key={i} className="w-auto">
                                    <img src={item.image} width={"100%"} style={{padding:"0px 25px"}} alt={`brand slider ${i}`}/>
                                 </div>
                                )
                        })}
                    </Slider>
                    {/* <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div> */}
                </div>
                <div>
                        <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Non–toxic</li>
                                <li>Excellent for portable water management</li>
                                <li>Exceptional quality and durability</li>
                                <li>Light weight</li>
                                <li>UV stabilized and maximum flow rate</li>
                                <li>Easy to install</li>
                                <li>High thermal stability</li>
                            </ul>
                    </div>
                </div>
                {/* product heading and color */}
                <ProductBanner/>
                <div className="container py-5">
                    <div className="row">
                    <div className="col-md-6 my-2" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <table className="product-table w-100">
                                    <thead>
                                        <tr><td className="product-heading heading" style={{color: "#004F87", border:"5px solid #004779"}} colSpan="4">Printing color</td></tr>
                                        <tr  className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                            <td>Class of Pipe</td>
                                            <td>Color</td>
                                        </tr>
                                    </thead>
                                    <tbody className="product-text text">
                                        <tr className="fw-bold"><td >2.5 KG/CM<sup>2</sup> (Class1)</td><td>Red</td></tr>
                                        <tr className="fw-bold"><td>4 KG/CM<sup>2</sup> (Class 2)</td><td>Blue</td></tr>
                                        <tr className="fw-bold"><td>6 KG/CM<sup>2</sup> (Class 3)</td><td>Green</td></tr>
                                        <tr className="fw-bold"><td>8 KG/CM<sup>2</sup> (Class 4)</td><td>Brown</td></tr>
                                        <tr className="fw-bold"><td>10 KG/CM<sup>2</sup> (Class 5)</td><td>Yellow</td></tr>
                                        <tr className="fw-bold"><td>12.5 KG/CM<sup>2</sup> (Class 6)</td><td>Black</td></tr>
                                        <tr className="fw-bold"><td>Plumbing Pipe</td><td>Pink</td></tr>
                                    </tbody>
                                </table>
                            </div>
                    </div>
                    <hr />
                    <div className="row">
                    <div className="col-xl-6 my-2">
                            <div className="my-2">
                                <div className="ps-sm-5" style={{border:"7px solid #004779", padding:"15px 30px",fontFamily:"Inter"}}>
                                    <h2 className="product-title title" style={{fontFamily:"Inter", textAlign: "center"}}>
                                        DIMENSION OF ISI WATER PIPE
                                        <hr style={{borderTop:"3px solid #004779", opacity:"1"}}/>
                                        All dimensions are in MM
                                    </h2>
                                </div>
                            </div>
                            <div className="table-wrapper" style={{margin:"0px auto"}}>
                                <table className="product-table">
                                    <thead>
                                        <tr className="product-text text fw-bold">
                                            <td  style={{backgroundColor:"#004779", color:"white"}}>Nominal Size(mm)</td>
                                            <td colSpan={2}>MEAN OD</td>
                                            <td colSpan={2}>OD AT ANY POINT</td>
                                            <td colSpan={2}>WALL THICKNESS</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specification.map((item, index) => {
                                            return(
                                                index === 0 ?
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td rowSpan={2} style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                </tr>
                                                <tr className="product-text small-text fw-bold my-2">
                                                    <td>{item.meanOD.min}</td>
                                                    <td>{item.meanOD.max}</td>
                                                    <td>{item.odAtAnyPoint.min}</td>
                                                    <td>{item.odAtAnyPoint.max}</td>
                                                    <td>{item.wellThickness.min}</td>
                                                    <td>{item.wellThickness.max}</td>
                                                </tr>
                                                </>
                                                :
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td>{item.meanOD.min}</td>
                                                    <td>{item.meanOD.max}</td>
                                                    <td>{item.odAtAnyPoint.min}</td>
                                                    <td>{item.odAtAnyPoint.max}</td>
                                                    <td>{item.wellThickness.min}</td>
                                                    <td>{item.wellThickness.max}</td>
                                                </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-xl-6 my-2">
                            <div className="my-2">
                                <div className="ps-sm-5" style={{border:"7px solid #004779", padding:"15px 30px",fontFamily:"Inter"}}>
                                    <h2 className="product-title title" style={{fontFamily:"Inter", textAlign: "center"}}>
                                        DIMENSION OF ISI WATER PIPE
                                        <hr style={{borderTop:"3px solid #004779", opacity:"1"}}/>
                                        All dimensions are in MM
                                    </h2>
                                </div>
                            </div>
                            <div className="table-wrapper" style={{margin:"0px auto"}}>
                                <table className="product-table">
                                    <thead>
                                        <tr className="product-text text fw-bold">
                                            <td  style={{backgroundColor:"#004779", color:"white"}}>Nominal Size(mm)</td>
                                            <td colSpan={2}>MEAN OD</td>
                                            <td colSpan={2}>OD AT ANY POINT</td>
                                            <td colSpan={2}>WALL THICKNESS</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specification2.map((item, index) => {
                                            return(
                                                index === 0 ?
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td rowSpan={2} style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Min</td>
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>Max</td>
                                                </tr>
                                                <tr className="product-text small-text fw-bold my-2">
                                                    <td>{item.meanOD.min}</td>
                                                    <td>{item.meanOD.max}</td>
                                                    <td>{item.odAtAnyPoint.min}</td>
                                                    <td>{item.odAtAnyPoint.max}</td>
                                                    <td>{item.wellThickness.min}</td>
                                                    <td>{item.wellThickness.max}</td>
                                                </tr>
                                                </>
                                                :
                                                <>
                                                <tr className="product-text small-text fw-bold">
                                                    <td style={{backgroundColor:"#004779", color:"white"}}>{item.nominalSize}</td>
                                                    <td>{item.meanOD.min}</td>
                                                    <td>{item.meanOD.max}</td>
                                                    <td>{item.odAtAnyPoint.min}</td>
                                                    <td>{item.odAtAnyPoint.max}</td>
                                                    <td>{item.wellThickness.min}</td>
                                                    <td>{item.wellThickness.max}</td>
                                                </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex text-white">
                                <div className="flex-grow-1">
                                <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Opacity Test</li>
                                <li>Reversion Test</li>
                                <li>Density Test</li>
                                <li>Effect on Water Test</li>
                                <li>Sulphated Ash Content Test</li>
                                <li>Vicat Softening Temperature Test</li>
                                <li>Internal Hydrostatic Pressure Test</li>
                                <li>Impact Test (Resistance to external blows at 0'c)</li>
                                <li>Physical Tests also(Checking Dimension Thickness)</li>
                            </ul>
                                </div>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div className="flex-grow-1">
                                    <img src={allProductsImage} alt="allProductsImage" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}