import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './About.css';
import ourvalue from "../../assets/Image content/Our value.png";
import Vision1 from "../../assets/Image content/vision 1.png";
import Vision2 from "../../assets/Image content/vision 2.png";
import vision from  "../../assets/Image content/vision.png";
import mission from  "../../assets/Image content/Mission.png";
import pipes2 from "../../assets/Images/pipes-2.png";
import pvcFitting from "../../assets/Images/PVCElectric.png";
import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// about silder images
import slider1 from "../../assets/about slider/about slider 1.png";
import slider2 from "../../assets/about slider/about slider 2.png";
import slider3 from "../../assets/about slider/about slider 3.png";
// unit images
import unit1 from "../../assets/Image content/about page unit-1.png";
import unit2 from "../../assets/Image content/about page unit-2.png";
import unit3 from "../../assets/Image content/about page unit-3.png";
import BlogThumbnail from "../blog/BlogThumbnail";


export default function About (){

    const aboutSlider = [
        {image: slider1},
        {image: slider2},
        {image: slider3},
    ]

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false
      };



      const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      // Adjust slidesToShow based on screen size
      if (window.innerWidth < 992) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1200) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    var personsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        accessibility: false,
        className:"person-slider",
      };

      const [screenSize, setScreenSize] = useState();
    
      useEffect(() => {
        const handleResize = () => {
          setScreenSize(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      
      const sliderRef = useRef(null);

      const moveSliderLeft = () => {
        if (sliderRef.current) {
          sliderRef.current.slickPrev();
        }
      };
    
      const moveSliderRight = () => {
        if (sliderRef.current) {
          sliderRef.current.slickNext();
        }
      };
    

    return(
        <div>
            <Navbar/>
            <div>
                {/* about heading */}
                {/* <div className="container">
                    <div className="d-md-flex justify-content-center align-items-center my-5">
                    <div className="">
                        <h2 className="about-page-heading title">ZED Certified <br /> ELECTRICAL CONDUITS & PVC PIPES MANUFACTURERS</h2>
                    </div>
                    <div className="w-lg-50">
                    <img src={allProduct} alt="allProduct" width={"100%"}/>
                    </div>
                </div>
                </div> */}
                <div className="certified" >
                    <Slider {...settings}>
                        {aboutSlider.map((item, i) => {
                            return(
                                <div key={i}>
                                    <img src={item.image} style={{width: "100%"}} alt={`about slider ${i}`} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>

                {/* about content 1*/}
                <div className="certified home-transparent1" >
                    <div className="container py-5">
                        {screenSize > 1300 ? 
                        <div className="row">
                        <div className="col-md-6">
                            <div className="">
                                <h4 className="about-page-content-heading heading">ELECTRICAL CONDUITS AND PVC PIPES MANUFACTURERS</h4>
                                <p className="about-page-content-text subheading">BALCO ® combines advanced technology with superior customer service to create the industry's most sophisticated and diverse products in PVC. <br /> Established in 1994, we began our journey by producing Rigid PVC Electric Conduits in international standard. In the 90's, to better serve the water management in the industrial and housing sector in Kerala we moved towards manufacturing uPVC pipes. Later on we added rigid pvc electric conduit fittings, solvent cement , garden hoses ,suction hoses ,water tank and flexible conduit to the list of products. We manufacture internationally standardized rigid PVC Electrical Conduits, Rigid PVC Electric Conduit Fittings, uPVC pipes and solvent cement. BALCO® has a legacy and goodwill of serving world class products for over 3 decades, with ISI and ISO 9000 : 2015 certification on all our products. Since 1994, we have been providing superior quality products in the finest quality.</p>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="about-content-col-img">
                            <div className="about-content-col-img1">
                                <img src={pipes2} alt="pipes2" width={"100%"}/>
                            </div>
                            <div className="about-content-col-img2">
                                <img src={pvcFitting} alt="pvcFitting" width={"100%"}/>
                            </div>
                            </div>
                        </div>
                    </div>:
                    <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h4 className="about-page-content-heading heading">ELECTRICAL CONDUITS AND PVC PIPES MANUFACTURERS</h4>
                            <p className="about-page-content-text subheading">BALCO ® combines advanced technology with superior customer service to create the industry's most sophisticated and diverse products in PVC. <br /> Established in 1994, we began our journey by producing Rigid PVC Electric Conduits in international standard. In the 90's, to better serve the water management in the industrial and housing sector in Kerala we moved towards manufacturing uPVC pipes. Later on we added rigid pvc electric conduit fittings, solvent cement , garden hoses ,suction hoses ,water tank and flexible conduit to the list of products. We manufacture internationally standardized rigid PVC Electrical Conduits, Rigid PVC Electric Conduit Fittings, uPVC pipes and solvent cement. BALCO® has a legacy and goodwill of serving world class products for over 3 decades, with ISI and ISO 9000 : 2015 certification on all our products. Since 1994, we have been providing superior quality products in the finest quality.</p>
                        </div>
                    </div>
                </div>
                        }
                    </div>
                </div>
                {/* Manufacturing */}
                <div className="about-transparent1">
                <div className="container py-5">
                    <div className="text-center">
                        <h4 className="Manufacturing title">Manufacturing Units</h4>
                        <div className="d-flex justify-content-center aline-item-center align-items-center my-4" style={{borderTop:"2px solid #004779", borderBottom:"2px solid #004779"}}>
                            <p className="mx-2 Manufacturing-title subheading my-0">Punalur</p>
                            <p className="mx-2 Manufacturing-title subheading my-0">Edamon</p>
                            <p className="mx-2 Manufacturing-title subheading my-0">Shencottai</p>
                            <p className="mx-2 Manufacturing-title subheading my-0">Kannur</p>
                        </div>
                        {/* <div className="row">
                            <div className="col-6 col-lg-3 my-2 p-2">
                            <div className="d-flex justify-content-around aline-item-center mx-3">
                                <span className="Manufacturing-circle"></span>
                                <div className="ms-2">
                                <p className="Manufacturing-number subheading">12,280</p>
                                <span className="Manufacturing-number-text text text-wrap">No. of completed projects</span>
                                </div>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3 my-2 p-2">
                            <div className="d-flex justify-content-around aline-item-center mx-3">
                                <span className="Manufacturing-circle"></span>
                                <div className="ms-2">
                                <p className="Manufacturing-number subheading">1,825</p>
                                <span className="Manufacturing-number-text text">No. of team members</span>
                                </div>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3 my-2 p-2">
                            <div className="d-flex justify-content-around aline-item-center mx-3">
                                <span className="Manufacturing-circle"></span>
                                <div className="ms-2">
                                <p className="Manufacturing-number subheading">37</p>
                                <span className="Manufacturing-number-text text">Awards</span>
                                </div>
                            </div>
                            </div>
                            <div className="col-6 col-lg-3 my-2 p-2">
                            <div className="d-flex justify-content-around aline-item-center mx-3">
                                <span className="Manufacturing-circle"></span>
                                <div className="ms-2">
                                <p className="Manufacturing-number subheading">256,861</p>
                                <span className="Manufacturing-number-text text">Volunteer</span>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-4 my-2" style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                                <div className="flew-grow-1">
                                    <img src={unit1} alt="unit-1" width={'100%'}/>
                                </div>
                            </div>
                            <div className="col-md-4 my-2" style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                                <div className="flew-grow-1">
                                    <img src={unit2} alt="unit-2" width={'100%'}/>
                                </div>
                            </div>
                            <div className="col-md-4 my-2" style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                                <div className="flew-grow-1">
                                    <img src={unit3} alt="unit-3" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* service about */}
                <div className="certified py-5 text-start">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex">
                        <div className="flex-grow-1 d-flex align-items-center" style={{ flexFlow: "column", justifyContent:"center" }}>
                            <h2 className="ourValue-heading title">OUR VALUES</h2>
                            <p className="ourValue-text text" style={{fontStyle:"normal"}}>Organisation values instil a sense of identity and purpose; add spirit to the  workplace; align & unify people; promote employee ownership; create  consistency and contribute to the organizational wellbeing from the  business perspective. </p>
                            <p className="ourValue-text text" style={{fontStyle:"normal"}}>Trust, Responsibility, Unity and Excellence have been identified as  fundamental to the BALCO Group.  Referred as TRUE Values of the  organisation, for us, these words mean the following: </p>
                            <ul className="ourValue-text text" style={{listStyle:"none", fontStyle:"normal"}}>
                                <li><span className="fw-bold">Trust: </span>Trust provides confidence in each other’s  capabilities and intentions of carrying out one’s duties  diligently & sincerely. </li>
                                <li><span className="fw-bold">Responsibility: </span>We will do business responsibly thereby  earning respect for all stakeholders. </li>
                                <li><span className="fw-bold">Unity: </span>Unity celebrates diversity. Synergizing the  collective experiences, varied skills, and unique talents  of every team member builds unstoppable momentum  and drives unimaginable achievements.  </li>
                                <li><span className="fw-bold">Excellence: </span>We strive to deliver the highest quality and  value possible through simple, easy and relevant  solutions.</li>
                            </ul>

                            <p className="ourValue-text text" style={{fontStyle:"normal"}}>Each member of the organisation is expected to believe; practise and insist  on the application of these values while fulfilling their roles and  responsibilities as they are at the core of our corporate identity.</p>
                            {/* <div className="row">
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6" style={{margin:"0px auto", verticalAlign:"center"}}>
                                    <button className="btn btn-gold"><span className="btn-square"></span> Know More <i className="bi bi-arrow-up-right ms-2"></i></button>
                                </div>
                            </div> */}
                        </div>
                        </div>
                        <div className="col-lg-6 d-flex">
                            <div className="flex-grow-1">
                                <img src={ourvalue} width={'100%'} alt="ourvalue" />
                            </div>
                        </div>
                    </div>
                    {/* what we do */}
                    <div className="row d-md-flex justify-content-center aline-item-center my-3">
                        <div className="col-md-6">
                        <div style={{textAlign:"start"}}>
                            <h4 className="about-page-content-heading heading">OUR SPECIALITIES</h4>

                            <p className="about-page-content-text text-start subheading">Serving people with fine electrical solutions for over 30 years, we manufacture ISI and ISO 9001:2015 certified rigid electric conduits.</p>
                        </div>
                        </div>
                        <div className="col-md-6" style={{display:'flex', justifyContent:'center', alignItems:"center", borderLeft:"5px solid #FFBF43"}}>
                        <div style={{textAlign:"center"}} className="mx-md-5">
                            <p className="about-page-content-heading heading">BALCO Pipes The Right Choice for Decades</p>
                        </div>
                        </div>
                    </div>
                     
                    
                    <hr style={{color:"#ffffff", opacity:'1'}}/>
                    {/* Vision */}
                    <div className="row d-md-flex justify-content-center aline-item-center align-items-center">
                        <div className="col-md-6 text-start">
                            <img src={Vision1} alt="Vision1" />
                            <p className="mt-4 ourValue-text subheading">Create value and make a positive impact in the lives of our stakeholders by being a responsible corporate entity consistently achieving organisational excellence mirroring Nation’s progress and prominence.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={vision} width={'100%'} alt="Vision" />
                        </div>
                    </div>
                    <hr style={{color:"#ffffff", opacity:'1'}}/>
                    {/* Vision */}
                    <div className="row d-md-flex justify-content-center aline-item-center align-items-center">
                        <div className="col-md-6">
                            <img src={mission} width={'100%'} alt="mission" />
                        </div>
                        <div className="col-md-6 text-start">
                            <img src={Vision2} alt="Vision2"/>
                            <p className="mt-4 ourValue-text subheading">BALCO Brand becomes the benchmark in Plastic Products and secures the pinnacle position in every customer’s order of preference.</p>
                        </div>
                    </div>
                    <hr style={{color:"#ffffff", opacity:'1'}}/>
                    </div>
                </div>
                {/* Person */}
                <div className="person-transparent1 py-5">
                <div className="container">
                    <div className="py-3">
                        <div className="d-flex " style={{alignItems:"center"}}>
                        <div style={{width:"10px", height:"10px",borderRadius:"50%",border: "3px solid #004779"}}></div>
                        <div style={{width:"50px",borderBottom:"1px solid #004779"}}></div>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <h4 className="Manufacturing title person-silder-button" style={{lineHeight:"1.2"}}>The amazing team <br /> behind our company</h4>
                            <div>
                                <button className="btn btn-blue mx-2 fs-5" style={{borderRadius:"50%"}} onClick={moveSliderLeft}><i className="bi bi-arrow-left"></i></button>
                                <button className="btn btn-blue mx-2 fs-5" style={{borderRadius:"50%"}} onClick={moveSliderRight}><i className="bi bi-arrow-right"></i></button>
                            </div>
                        </div>
                       
                        <div className="d-flex " style={{ alignItems: "center", justifyContent: "center" }}>
                                <div style={{ width: "50px", borderBottom: "1px solid #004779" }}></div>
                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", border: "1px solid #004779" }}></div>
                                <div style={{ width: "50px", borderBottom: "1px solid #004779" }}></div>
                            </div>
                    </div>
                    <div className="row d-flex justify-content-center aline-item-center">
                        <Slider {...personsettings} ref={sliderRef}>
                        <div className="col-4 text-center mx-sm-2 d-flex justify-content-center aline-item-center">
                            <div>
                            <div className="photo-rectangle">
                                {/* <img className="photo-rectangle-img" src={person1} alt="person1" /> */}
                            </div>
                            <div className="">
                                    <h4 className="Manufacturing subheading" style={{lineHeight:"1.2"}}>Team Members</h4>
                                    <p>Team Members Post</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 text-center  mx-sm-2 d-flex justify-content-center aline-item-center">
                            <div>
                            <div className="photo-rectangle">
                                {/* <img className="photo-rectangle-img" src={person2} alt="person1" /> */}
                            </div>
                            <div className="">
                                    <h4 className="Manufacturing subheading" style={{lineHeight:"1.2"}}>Team Members</h4>
                                    <p>Team Members Post</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 text-center  mx-sm-2 d-flex justify-content-center aline-item-center">
                            <div>
                            <div className="photo-rectangle">
                                {/* <img className="photo-rectangle-img" src={person2} alt="person1" /> */}
                            </div>
                            <div className="">
                                    <h4 className="Manufacturing subheading" style={{lineHeight:"1.2"}}>Team Members</h4>
                                    <p>Team Members Post</p>
                                </div>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
                </div>
                
                {/* Project */}
                <div className="certified py-5">
                    <div className="container "> {/* project-div */}
                    <div className="row d-flex justify-content-center aline-item-center align-items-center">
                        {/* <div className="col-lg-5 px-4 my-2">
                            <h4 className="project-title title">CSR Driving Change for a Sustainable Future</h4>
                            <p className="project-text subheading">At BALCO, Corporate Social Responsibility (CSR) is not just a commitment; it's a driving force for positive change towards a sustainable future.
                                Our CSR initiatives focus on creating lasting impacts in areas such as environmental conservation, community development, and social welfare. Through strategic partnerships and innovative programs, we aim to address pressing societal challenges while promoting sustainable practices across our operations.
                                Join us in shaping a better tomorrow through our CSR efforts, where every action counts towards building a more inclusive, resilient, and environmentally conscious world. Together, we can drive meaningful change for a brighter future.</p>
                            <div className="mt-5">
                                <button className="btn btn-gold" onClick={() => navigate('/blog')}><span className="btn-square"></span> Read More <i className="bi bi-arrow-up-right ms-2"></i></button>
                            </div>
                        </div>  */}
                        <div className="col-lg-12  my-2">
                            <iframe 
                                width="100%" 
                                height="443px" 
                                src="https://www.youtube.com/embed/L4Y2kndKKho?si=PltJJVj6qXN6SGZV" 
                                title="YouTube video player" 
                                style={{border:"0px"}} 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowFullScreen
                            ></iframe> 
                        </div> 
                    </div>
                    </div>

                    <div className="container my-4">
                    {/* <div className="row d-sm-flex justify-content-center aline-item-center">
                        <div className="col-md-3 col-sm-6 project-rectangle-div-box my-2 mx-sm-2">
                            <div className="">
                                <div className="project-rectangle-div-insidebox mb-3">
                                    <img src={aboutImg1} alt="aboutImg1" width={'100%'}/>
                                </div>
                                <h5 className="project-rectangle-div-text subheading">Agriculture & rural development projects</h5>
                            </div>
                        </div> 
                        <div className="col-md-3 col-sm-6 project-rectangle-div-box my-2 mx-sm-2">
                            <div className="">
                                <div className="project-rectangle-div-insidebox mb-3">
                                    <img src={aboutImg2} alt="aboutImg2" width={'100%'}/>
                                </div>
                                <h5 className="project-rectangle-div-text subheading">Agriculture & rural development projects</h5>
                            </div>
                        </div> 
                        <div className="col-md-3 col-sm-6 project-rectangle-div-box my-2 mx-sm-2">
                            <div className="">
                                <div className="project-rectangle-div-insidebox mb-3">
                                    <img src={aboutImg3} alt="aboutImg3" width={'100%'}/>
                                </div>
                                <h5 className="project-rectangle-div-text subheading">Agriculture & rural development projects</h5>
                            </div>
                        </div> 
                        <div className="col-md-3 col-sm-6 project-rectangle-div-box my-2 mx-sm-2">
                            <div className="">
                                <div className="project-rectangle-div-insidebox mb-3">
                                    <img src={aboutImg4} alt="aboutImg4" width={'100%'}/>
                                </div>
                                <h5 className="project-rectangle-div-text subheading">Agriculture & rural development projects</h5>
                            </div>
                        </div> 
                    </div> */}
                    <div className="text-center">
                        <h4 className="blog-heading heading text-white text-center">News & Articles</h4>
                    </div>
                    <BlogThumbnail requiredData={3}/>
                    </div>
                </div>
            </div>
            <Footer/>

            <style>
        {`
                .slick-next {
                    right: 15px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #dba63a;
                }
                .slick-prev {
                    left: 15px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #dba63a;
                }
                `}
      </style>
        </div>
    )
}