import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import Greenhosesimage from '../../assets/Images/green hoses.png';
import greenpipeimage1 from '../../assets/Images/green hoses 1.png';
import greenpipeimage2 from '../../assets/Images/green hoses 2.png';
import ProductBanner from "./ProductBanners";

export default function GreenHoses () {
    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div style={{background: "#D9D9D9"}}>
                    <div className="container">
                    <div className="d-md-flex justify-content-center aline-item-center align-items-center my-5">
                        <div className="">
                            <h2 className="product-title title">Premium PVC Garden and Suction Hoses! Your Reliable Watering Solutions</h2>
                        </div>
                        <div className="">
                            <img src={Greenhosesimage} alt="SolventImage" width={"100%"}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-6">
                        <h2 className="product-heading heading">GARDEN HOSES</h2>
                        <p className="product-text text">We provide an array of PVC garden hoses that are produced utilizing premium raw materials. These are used to provide water in patio, nurseries and yards in residential buildings and other similar environments. Also available in different sizes & lengths 15meters and 30 meters in 20mm (1/2”), 25mm (3/4”), 32 mm (1”) sizes.</p>
                    </div>
                    <div className="col-sm-6">
                        <div className="m-4">
                            <img src={greenpipeimage1} alt="greenpipeimage1" width={"100%"} height={400}/>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 mb-4">
                    <div className="col-sm-6">
                        <div className="m-4">
                            <img src={greenpipeimage2} alt="greenpipeimage2" width={"100%"} height={400}/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <h2 className="product-heading heading">SUCTION HOSES</h2>
                        <p className="product-text text">We provide an array of PVC garden hoses that are produced utilizing premium raw materials. These are used to provide water in patio, nurseries and yards in residential buildings and other similar environments. Also available in different sizes & lengths 15meters and 30 meters in 20mm (1/2”), 25mm (3/4”), 32 mm (1”) sizes.</p>
                    </div>
                </div>
                {/* <div className="row my-4 text-center">
                    <div className="col-sm-3 col-xs-6">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                </div> */}
                <hr className="black"/>
                <div className="my-4">
                 <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Long lasting</li>
                                <li>Easy installation</li>
                                <li>Non-destructive in nature</li>
                                <li>Lightweight and easy to handle, introduce and transport</li>
                                <li>High mechanical quality for all applications</li>
                                <li>Smooth and adaptability for long span twisting</li>
                            </ul>
                </div>
                </div>
                {/* product heading and color */}
                <ProductBanner/>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Marking</li>
                                <li>Durability of Marking</li>
                                <li>Wall Thickness</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div className="flex-grow-1">
                                    <img src={Greenhosesimage} alt="Greenhosesimagelast" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}