import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';

export default function InvestorGrievances() {
    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Investor Grievances</h2>
                    <div>
                    <div className="investor-thead" style={{height:"50px", borderRadius:"31px"}}>

                    </div>
                    <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                        1. REGISTRAR TO THE ISSUE <br />
                        CAMEO CORPORATE SERVICES LIMITED <br />
                        “Subramanian Building”, No. 1, Club House Road, <br />
                        Chennai -600002, India <br />
                        Contact Person: Ms. K. SREEPRIYA <br />
                        Telephone:+91-44-40020700 <br />
                        Email:ipo@cameoindia.comWebsite:https://cameoindia.com/
                    </div>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}