// import useEffect from 'react';

// const ZohoChat = () => {
//   useEffect(() => {
//     // Initialize Zoho SalesIQ
//     window.$zoho = window.$zoho || {};
//     window.$zoho.salesiq = window.$zoho.salesiq || {
//       widgetcode: "siq1836548f969413ac37809b33ca61eb41cc027fad7c94087b0cbf807d4a24cbea",
//       values: {},
//       ready: function() {}
//     };

//     // Create script element
//     const script = document.createElement("script");
//     script.type = "text/javascript";
//     script.id = "zsiqscript";
//     script.defer = true;
//     script.src = "https://salesiq.zohopublic.in/widget";

//     // Append script to the body
//     const firstScript = document.getElementsByTagName("script")[0];
//     firstScript.parentNode.insertBefore(script, firstScript);

//     return () => {
//       // Cleanup script if needed
//       const scriptElement = document.getElementById('zsiqscript');
//       if (scriptElement) {
//         scriptElement.parentNode.removeChild(scriptElement);
//       }
//     };
//   }, []);

//   return null;
// };

// export default ZohoChat;


import React, { useEffect, useRef } from 'react';

const ZohoForm = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.src = 'https://forms.zohopublic.in/solveplastic/form/LeadForm/formperma/xOQHiWBXCwy73o70xtcmeKcsZdlq51KnFz35wVJ4PxY?zf_rszfm=1';
    iframe.style.border = "none";
    iframe.style.height = "592px";
    iframe.style.width = "100%";
    iframe.style.transition = "all 0.5s ease";
    iframe.setAttribute("aria-label", 'Enquiry Form');

    const divElement = iframeRef.current;
    divElement.appendChild(iframe);

    const handleMessage = (event) => {
      const evntData = event.data;
      if (evntData && evntData.constructor == String) {
        const zf_ifrm_data = evntData.split("|");
        if (zf_ifrm_data.length == 2) {
          const zf_perma = zf_ifrm_data[0];
          const zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
          const iframe = divElement.getElementsByTagName("iframe")[0];
          if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
            const prevIframeHeight = iframe.style.height;
            if (prevIframeHeight != zf_ifrm_ht_nw) {
              iframe.style.height = zf_ifrm_ht_nw;
            }
          }
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div ref={iframeRef}></div>
  );
};

export default ZohoForm;