import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Home.css';
import aboutImage from "../../assets/Image content/about-image.png";
import starIcon from "../../assets/Icons/icon_star.png";
import ourvalue from "../../assets/Image content/Our value.png";
import { useState } from "react";

// Images
import product1 from "../../assets/Images/pipes-1.png"
import product2 from "../../assets/Images/pipes-2.png"
import product3 from "../../assets/Images/pipes-3.png"
import pipesimage from '../../assets/Images/fittings 1.png';
import product5 from '../../assets/Images/solvent 2.png';
import product6 from '../../assets/Images/water-tank 1.png';
import ceoimage from '../../assets/Images/ceo image.png';
import ceoimagecircle from '../../assets/Images/ceo image circle.png';
import homeimage3 from '../../assets/Images/home-image-3.png';

// Home silder images
import Artboard1 from  "../../assets/home slider/Artboard 1.jpg";
import Artboard2 from  "../../assets/home slider/Artboard 2.png";
import Artboard3 from  "../../assets/home slider/Artboard 3.png";
import Artboard4 from  "../../assets/home slider/Artboard 4.png";

import slider1 from "../../assets/home slider/Home slide 1.png";
import slider2 from "../../assets/home slider/Home slide 2.png";
import slider3 from "../../assets/home slider/Home slide 3.png";
import slider4 from "../../assets/home slider/Home slide 4.png";
import slider5 from "../../assets/home slider/Home slide 5.png";
import slider6 from "../../assets/home slider/Home slide 6.png";
import slider7 from "../../assets/home slider/Home slide 7.png";
import slider8 from "../../assets/home slider/Home slide 8.png"

// Home brand slider images
import brandslider1 from "../../assets/home slider/Home brand slider1.png";
import brandslider2 from "../../assets/home slider/Home brand slider2.png";
import brandslider3 from "../../assets/home slider/Home brand slider3.png";
import brandslider4 from "../../assets/home slider/Home brand slider4.png";
import brandslider5 from "../../assets/home slider/Home brand slider5.png";
import brandslider6 from "../../assets/home slider/Home brand slider6.png";
import brandslider7 from "../../assets/home slider/Home brand slider7.png";

// Testimonial slider images
import testimonail1 from "../../assets/Testimonial-images/testimonial-1.png";
import testimonail2 from "../../assets/Testimonial-images/testimonial-2.png";
import testimonail3 from "../../assets/Testimonial-images/testimonial-3.png";
import testimonail4 from "../../assets/Testimonial-images/testimonial-4.png";
import testimonail5 from "../../assets/Testimonial-images/testimonial-5.png";
import testimonailProfile1 from "../../assets/Testimonial-images/testimonial-profile-1.png";
import testimonailProfile2 from "../../assets/Testimonial-images/testimonial-profile-2.png";
import testimonailProfile3 from "../../assets/Testimonial-images/testimonial-profile-3.png";
import testimonailProfile4 from "../../assets/Testimonial-images/testimonial-profile-4.png";
import testimonailProfile5 from "../../assets/Testimonial-images/testimonial-profile-5.png";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import BlogThumbnail from "../blog/BlogThumbnail";

export default function HomePage() {

    const navigate = useNavigate();

    const productList = [
        {product: product1, title: "Rigid PVC Electric conduits", text: "BALCO® electrical conduits are manufactured in Rigid PVC which makes it strong to withstand any amount of pressure and heat.", linkto: "/product/pvc-electricals"},
        {product: product2, title: "UPVC PIPES", text:"BALCO® pipes are made of un plasticized poly vinyl chloride (uPVC) making it ideal for cold water plumbing application.", linkto: "/product/pvc-water-pipes"},
        {product: product3, title: "Garden & Suction  Hoses", text:"BALCO® Garden hoses are made of high quality virgin plastic granules ,helps you in easy management of water in garden and patio or other residential purposes.", linkto: "/product/green-hoses"},
        {product: pipesimage, title: "Rigid PVC Electric conduits Fittings", text: "We manufactures and supplies wide range of supreme quality electrical fittings including Connectors, Cable, Clamps, Wire.", linkto: "/product/pvc-electricals-fitting" },
        {product: product5, title: "Solvent Cement", text: "BALCO Solvent Cement is a colorless adhesive specifically designed for joining PVC, C-PVC, and ABS pipes and fittings. This specialized cement plays a crucial role in creating strong and reliable connections in plumbing and piping systems.", linkto: "/product/solvent" },
        {product: product6, title: "Water Tank", text: "BALCO Water Tanks are meticulously crafted to offer long-lasting durability, providing a reliable solution for storing water while ensuring its safety and security. These tanks are specifically designed to prevent the growth of algae, maintaining water quality at its best.", linkto: "/product/water-tanks" },
    ]


    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false
      };

    const HomeSlider = [
        {image: Artboard1},
        {image: Artboard2},
        {image: Artboard3},
        {image: Artboard4},
        {image: slider1},
        {image: slider2},
        {image: slider3},
        {image: slider4},
        {image: slider5},
        {image: slider6},
        {image: slider7},
        {image: slider8}
    ];

    const HomeBrandSlider = [
        {image: brandslider1},
        {image: brandslider2},
        {image: brandslider3},
        {image: brandslider4},
        {image: brandslider5},
        {image: brandslider6},
        {image: brandslider7},
    ];

    const TestimonailSlider = [
        {image: testimonail1, clientImage: testimonailProfile1, clientName: "Deepam", textCenter: true,text: ["This is to certify that, BALCO ISI marked electrical conduit pipes and its accessories are used. The material supplied is as per the standard specification and the performance of the products and their services are extremely good."]},
        {image: testimonail2, clientImage: testimonailProfile2, clientName: "Kerala Water Authority", textCenter: true,text: ["SOLVE PLASTIC PRODUCTS PRIVATE LTD, Edamon has supplied UPVC pipes for the various projects under this Sub Division lor the last 3 years. The pipe supplied by the company has been found as per specification and satisfactory quality."]},
        {image: testimonail3, clientImage: testimonailProfile3, clientName: "Super Constructions", textCenter: true,text: ["BALCO ISI marked electrical Conduit pipes and its accessories are used. The material supplied is as per the standard specification and the performance of the products and their services are extremely good."]},
        {image: testimonail4, clientImage: testimonailProfile4, clientName: "Surekha", textCenter: true,text: ["BALCO ISI marked Water pipes and its accessories are widely used in our various Sports construction projects in Kerala. Few of the projects are listed below.","9’ Field Sports Arena, Attingal Trivandrum, Turfico Vithura, Trivandrum, Manchester Sports hub, Kollam, BA Phoenix Sports Hub, Kadakkal, Sama Sports, Cochin, Kecheri Sports, Thrissur, Dribblers Park, Kannur, Pattuvam Turf, Kannur etc..","The material supplied is as per the standard specification and the performance of the products and their service is extremely good."]},
        {image: testimonail5, clientImage: testimonailProfile5, clientName: "JC", textCenter: false,text: ["BALCO ISI marked electrical conduit pipes and its accessories are widely used in our various building projects in Kerala. The material supplied is as per the standard specification and the performance of the products and their service are extremely good.", "List of Recent Projects:",
                "1. Cochin Cancer research center Project Kalamasery Ernakulam Kerala",
                "2. Surgical Block Govt medical College Kottayam Kerala",
                "3. Govt Medical college 2nd Phase Konni Pathanamthitta Kerala"
                ]},
    ];


    const CompanyClient = [
        {image: ceoimage, clientImage: ceoimagecircle, clientName:"Darlene Robertson", clientPosition:"CEO" ,text:[]},
        {image: ceoimage, clientImage: ceoimagecircle, clientName:"Darlene Robertson", clientPosition:"CEO" , text:["“ We have used BALCO Pipes in various applications, from water supply to drainage systems, and they have consistently exceeded our expectations. The durability and reliability of BALCO Pipes have helped us deliver projects on time and within budget. ”", "Furthermore, the support and service provided by the BALCO team have been exceptional. Their expertise and willingness to go the extra mile have made them a trusted partner for us."]},
    ];

    const [isBlogData, setIsBlogData] = useState(false);

    return(
        <div>
            <Navbar/>
            <div className="">
                {/* Content Heading */}
                {/* <div className="container d-lg-flex justify-content-center align-items-center my-5">
                    <div className="home-heading heading">
                    Welcome to Balco Pipes
                    <p className="title">
                        Your Trusted Partner for High-Quality
                        Pipes and Solutions
                    </p>
                    </div>
                    <div className="w-lg-50">
                        <img src={pipesimage} style={{width: "100%"}} alt="pipesimage" />
                    </div>
                </div> */}
                <div className="">
                    <Slider {...settings}>
                        {HomeSlider.map((item, i) => {
                            return(
                                <div key={i}>
                                    <img src={item.image} style={{width: "100%"}} alt={`home slider ${i}`} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                {/* Content Subheading blue color*/}
                <div className="certified home-transparent1 py-sm-5" >
                    <div className="container pt-3 pb-5">
                    <div className="width-50">
                        <p className="text certified-left-margin">Explore our comprehensive product range, learn about our company values, and discover how we can meet your piping needs with reliability and expertise.</p>
                        <div className="mt-5">
                        {/* <button className="btn btn-gold"><span className="btn-square"></span> Get a Quote <i className="bi bi-arrow-up-right ms-2"></i></button> */}
                    </div>
                    </div>
                    <div className="row my-2">
                        <div className="col-md-4 my-2">
                        <div className="" style={{backgroundColor: '#ECECEC', padding: '5px 30px'}}>
                            <div className="my-4">
                                <button className="btn btn-blue" onClick={() => navigate("/quote")}><span className="btn-square"></span> Get a Quote <i className="bi bi-arrow-up-right ms-2"></i></button>
                            </div>
                            <hr style={{color:"white"}}/>
                            <div>
                                <h4 className="home-subheading-white-title subheading">Become a BALCO Distributor</h4>
                                <p className="home-subheading-white-text text">Are you interested in becoming a part of BALCO Pipes' growing distribution network? We welcome inquiries from potential distributors who share our commitment to quality, reliability, and customer satisfaction. Join us in delivering excellence and driving success in the piping industry.</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-8 my-2">
                        <div className="home-subheading-text-box mx-3">
                            <h4 className="home-subheading-text-box-heading title">MSME Zed Certified <br /> ELECTRICAL CONDUITS AND PVC PIPES MANUFACTURERS</h4>
                            {/* <div className="d-flex">
                                <img src={award} width={60} height={60} alt="award" />
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                            </div> */}
                            <p className="home-subheading-text-box-subheading subheading">BALCO Pipes is a renowned provider of premium piping solutions, serving industries across the globe with our extensive range of products.</p>
                        <hr style={{color:"white"}}/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* About Company div */}
                <div className="container my-2">
                    <span className="home-about-message subheading">
                        ABOUT COMPANY
                        <div className="d-flex " style={{alignItems:"center"}}>
                        <div style={{width:"10px", height:"10px",borderRadius:"50%", border: "3px solid #004779"}}></div>
                        <div style={{width:"50px",borderBottom:"1px solid #004779"}}></div>
                        </div>
                        <p className="home-heading title my-2" style={{marginBottom:"0px", lineHeight:"1.2"}}>ELECTRICAL CONDUITS AND <br /> PVC PIPES MANUFACTURERS</p>
                    </span>
                    <div className="row">
                        <div className="col-md-6 my-2">
                        <div style={{ position: "relative" }}>
                            <img src={aboutImage} width={'100%'} height={600} alt="aboutImage" />
                            <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                            <button className="btn btn-gold"  onClick={() => navigate("/about")}><span className="btn-square"></span> More About Us <i className="bi bi-arrow-up-right ms-2"></i></button>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 my-2">
                        <div className="mx-3">
                            <h4 className="home-heading subheading" style={{ lineHeight: "1.5", paddingBottom: "20px", borderBottom: "3px solid #004F87" }}>BALCO ®  combines advanced technology with superior customer service to create the industry's most sophisticated and diverse products in PVC.</h4>
                            <p className="home-about-message text pt-3" style={{textAlign:"justify"}}>Established in 1994, we began our journey by producing Rigid PVC Electric Conduits in international standard. In the 90's, to better serve the water management in the industrial and housing sector in Kerala we moved towards manufacturing uPVC pipes. Later on we added rigid pvc electric conduit fittings, solvent cement , garden hoses ,suction hoses ,water tank and flexible conduit to the list of products. We manufacture internationally standardized rigid PVC Electrical Conduits, Rigid PVC Electric Conduit Fittings, uPVC pipes and solvent cement. BALCO® has a legacy and goodwill of serving world class products for over 3 decades, with ISI and ISO 9000 : 2015 certification on all our products. Since 1994, we have been providing superior quality products in the finest quality.</p>
                        </div>
                        </div>
                    </div>
                </div>
                {/* What we do */}
                <hr />
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-6 my-2 d-flex">
                        <div className="flex-grow-1">
                        <div className="home-whatdowe subheading" style={{marginBottom:"0px", lineHeight:"1.2"}}>
                    {/* WHAT WE DO <br /> */}
                    <span className="home-do title">WHAT WE ARE</span>
                    </div>
                    <p className="my-2 text">BALCO has been manufacturing a variety of highly durable products since 1994.</p>
                    <div className="my-3">
                        <div className="card my-2 p-4" style={{boxShadow:"0px 3px 30px 0px #0F1B241A"}}>
                            <h5 className="home-whatwedo-card-header subheading">Manufacturing Excellence</h5>
                            <p className="home-whatwedo-card-text text">BALCO Pipes with 4 ZED certified manufacturing units strategically located across Kerala & Tamil Nadu. Our state-of-the-art facilities are equipped to deliver unmatched quality and reliability in every product we offer.</p>
                        </div>
                        <div className="card my-2 p-4" style={{boxShadow:"0px 3px 30px 0px #0F1B241A"}}>
                            <h5 className="home-whatwedo-card-header subheading">Quality Assurance</h5>
                            <p className="home-whatwedo-card-text text">Quality is the cornerstone of our operations at BALCO Pipes. We prioritize using virgin materials in our manufacturing processes, ensuring the highest level of product purity and durability. We refrain from using harmful materials like plasticizers, adhering to strict safety standards for your peace of mind. Additionally, our commitment to minimal use of fillers maintains the integrity and performance of our pipes, guaranteeing exceptional quality in every project.</p>
                        </div>
                    </div>
                    <div className="my-3 mx-2">
                        <p className="home-whatdowe-card-subtext text">We ensure our products are non-hazardous in nature as per the limit prescribed by the Bureau of Indian standards.</p>
                    </div>
                        </div>
                        </div>
                        <div className="col-md-6 my-2 d-flex" >
                            <div className="flex-grow-1">
                                <img src={homeimage3} alt="homeimage3" width={"100%"} height={600}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/* What we Offer */}
                <div className="certified py-5">
                    <div className="container">
                        <div className="home-whatdowe subheading py-3" style={{color:"#ffffff",marginBottom:"0px", lineHeight:"1.2"}}>
                        WHAT WE DO <br />
                        <span className="home-do title" style={{color:"#ffffff"}}>OUR SERVICES</span>
                        </div>
                        <div className="row">
                            {productList.map((item,index) => {
                                return(
                                    <div className="col-lg-4 col-md-6 my-3 d-flex" key={index}>
                                        <div className="product-box flex-grow-1"  style={{backgroundColor:"#ffffff"}}>
                                            <div className="w-100">
                                                <img src={item.product} alt="product1" width="100%" height="256" className="image-container-img"/>
                                            </div>
                                            <div className="p-3">
                                                <h5 className="home-whatwedo-card-header subheading">{item.title}</h5>
                                                <p className="product-box-text text">{item.text}</p>
                                                <p className="product-box-readmore home-whatwedo-card-text small-text" style={{cursor:"pointer"}} onClick={() => navigate(item.linkto)}>Read More</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>
                {/* Employee */}
                {/* <div className="container my-5">
                    <Slider {...settings}>
                        {CompanyClient.map((item, i) => {
                            return (
                                <div className="d-flex justify-content-center align-items-center" key={i}>
                                <div className="row">
                                    <div className="col-lg-6 d-flex justify-content-center align-items-center my-2">
                                        <img src={item.image} alt="ceoimage" width={'100%'} />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div >
                                            <div className="d-flex justify-content-center align-items-center my-2">
                                                {
                                                    [1, 2, 3, 4, 5].map((item, index) => {
                                                        return (
                                                            <img src={starIcon} alt="starIcon" className="mx-2" key={index} />
                                                        )
                                                    })
                                                }
                                                <span className="ms-3 employee-rating subheading">4.9 / 5.00</span>
                                            </div>
                                            <div className="py-3">
                                                {item.text.map((text, i) => {
                                                    return(
                                                        <p className="employee-text text" key={i}>{text}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className="text-center">
                                                <div style={{width:"100px", margin:"0px auto"}}>
                                                <img src={item.clientImage} width={"100%"} alt="ceoimagecircle" />
                                                </div>
                                                <h5 className="employee-text text" style={{ lineHeight: "1.2" }}>{item.clientName}</h5>
                                                <span className="home-about-message small-text" style={{ lineHeight: "1.2" }}>{item.clientPosition}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div> */}
                {/* Brand Client */}
                <div className="container my-5">
                    <Slider {...settings}>
                        {TestimonailSlider.map((item, i) => {
                            return (
                                <div className="row d-md-flex justify-content-center aline-item-center align-items-center" key={i}>
                                    <div className="col-lg-6 d-flex justify-content-center align-items-center my-2">
                                        <img src={item.image} alt="ceoimage" width={'100%'} />
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div >
                                            <div className="py-3">
                                                {item.text.map((text, i) => {
                                                    return(
                                                        <p className={`employee-text text ${!item.textCenter && 'text-start'}`} key={i}>{text}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className="text-center">
                                                <div style={{width:"100px", margin:"0px auto"}}>
                                                <img src={item.clientImage} width={"100%"} alt="ceoimagecircle" />
                                                </div>
                                                <h5 className="employee-text text" style={{ lineHeight: "1.8" }}>{item.clientName}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                {/* Client */}
                <div className="certified py-5 text-center home-transparent2">
                    <div className="container px-lg-5">
                    <div className="">
                        <span className="client-subtitle text">Testimonial</span>
                        <h4 className="client-title title" style={{color:"#ffffff",marginBottom:"0px", lineHeight:"1.2"}}>What Our Client Say</h4>
                    </div>
                    <div className="container row my-5 px-lg-5">
                        <div className="col-lg-6 my-5">
                        {/* <img src={Client} width={'100%'} alt="Client"/> */}
                        <div className="profile-card">
                        <p className="ourValue-text text" style={{color:"#757575", fontStyle:"normal"}}>As an architect, I rely on high-quality materials to bring my designs to life. BALCO Pipes have been my go-to choice for plumbing solutions due to their superior quality and durability. The wide range of products offered by BALCO allows me to find the perfect fit for every project, ensuring a seamless and efficient plumbing system. I highly recommend BALCO Pipes to fellow professionals and homeowners looking for reliability and performance. Raghunath Architect</p>
                            <div className="profile-circle"></div>
                            <div className="profile-name">Raghunath Architect</div>
                            {/* <div className="profile-company text">ReConstruction LLC</div> */}
                        </div>
                            </div>
                        <div className="col-lg-6 my-5">
                        <div className="profile-card">
                        <p className="ourValue-text text" style={{color:"#757575", fontStyle:"normal"}}>Being a contractor, I understand the importance of using reliable products to deliver exceptional results. BALCO Pipes have consistently met and exceeded my expectations, providing unmatched quality and performance. From electrical conduits to water pipes, BALCO offers a comprehensive range of products that cater to diverse project needs. Their commitment to excellence and customer satisfaction makes them my preferred choice for plumbing solutions.</p>
                            <div className="profile-circle"></div>
                            <div className="profile-name">Pradesh  Contractor</div>
                            {/* <div className="profile-company text">ReConstruction LLC</div> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* our Clients */}
                <div className="container home-transparent3" >
                    <div className="text-center">
                        <div className="py-3">
                            <h4 className="client-heading title">Our Esteemed Clients</h4>
                            <div className="d-flex " style={{ alignItems: "center", justifyContent: "center" }}>
                                <div style={{ width: "50px", borderBottom: "1px solid #004779" }}></div>
                                <div style={{ width: "10px", height: "10px", borderRadius: "50%", border: "1px solid #004779" }}></div>
                                <div style={{ width: "50px", borderBottom: "1px solid #004779" }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 my-2">
                        <div>
                            <span className="home-about-message text">Trusted Best Company</span>
                            <div className="d-flex " style={{alignItems:"center"}}>
                            <div style={{width:"10px", height:"10px",borderRadius:"50%", border: "3px solid #004779"}}></div>
                            <div style={{width:"50px",borderBottom:"1px solid #004779"}}></div>
                            </div>
                            <h4 className="client-box-title heading">We Help You Build On Your <span className="client-box-title" style={{fontFamily: "Cormorant", color:"#004779"}}>Past & Future Projects.</span></h4>
                            <p className="client-box-text subheading">We are pleased to share that several of our esteemed clients have been steadfast users of BALCO products for numerous years. Their continued loyalty reflects the trust and satisfaction they derive from the exceptional quality, reliability, and performance offered by BALCO. These enduring partnerships underscore BALCO's reputation for excellence and its unwavering commitment to delivering tailored solutions that cater to the diverse requirements of clients across multiple industries.</p>
                            <div>
                                <button className="btn btn-blue" onClick={() => navigate("/about")}><span className="btn-square"></span> More Service <i className="bi bi-arrow-up-right ms-2"></i></button>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 my-2">
                            <div>
                            <Slider {...settings}>
                                {HomeBrandSlider.map((item, i) => {
                                    return(
                                        <div key={i}>
                                            <img src={item.image} style={{width: "100%"}} alt={`home slider ${i}`} />
                                        </div>
                                    )
                                })}
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Services */}
                <div className="certified pt-5 pb-2">
                    <div className="container">
                    <div className="row my-3">
                    <div className="col-md-6 d-flex my-2">
                        <div className="flex-grow-1 align-items-start" style={{ flexFlow: "column", justifyContent:"space-evenly" }}>
                            <div>
                                <h2 className="ourValue-heading title text-center">OUR VALUES</h2>
                                <p className="ourValue-text text" style={{fontStyle:"normal"}}>Organisation values instil a sense of identity and purpose; add spirit to the  workplace; align & unify people; promote employee ownership; create  consistency and contribute to the organizational wellbeing from the  business perspective. </p>
                                <p className="ourValue-text text" style={{fontStyle:"normal"}}>Trust, Responsibility, Unity and Excellence have been identified as  fundamental to the BALCO Group.  Referred as TRUE Values of the  organisation, for us, these words mean the following: </p>
                                <ul className="ourValue-text text" style={{listStyle:"none", fontStyle:"normal"}}>
                                    <li><span className="fw-bold">Trust: </span>Trust provides confidence in each other’s  capabilities and intentions of carrying out one’s duties  diligently & sincerely. </li>
                                    <li><span className="fw-bold">Responsibility: </span>We will do business responsibly thereby  earning respect for all stakeholders. </li>
                                    <li><span className="fw-bold">Unity: </span>Unity celebrates diversity. Synergizing the  collective experiences, varied skills, and unique talents  of every team member builds unstoppable momentum  and drives unimaginable achievements.  </li>
                                    <li><span className="fw-bold">Excellence: </span>We strive to deliver the highest quality and  value possible through simple, easy and relevant  solutions.</li>
                                </ul>

                            <p className="ourValue-text text" style={{fontStyle:"normal"}}>Each member of the organisation is expected to believe; practise and insist  on the application of these values while fulfilling their roles and  responsibilities as they are at the core of our corporate identity.</p>
                            </div>
                            <div className="text-start">
                                <button className="btn btn-gold" onClick={() => navigate("/about")}>
                                    <span className="btn-square"></span> Know More <i className="bi bi-arrow-up-right ms-2"></i>
                                </button>
                            </div>

                            {/* <div className="row">
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6">
                                <div className="mx-3">
                                    <h4 className="home-subheading-text-number heading">298+</h4>
                                    <p className="home-subheading-text-number-text subheading">Awards Won</p>
                                </div>
                                </div>
                                <div className="col-6" style={{margin:"0px auto", verticalAlign:"center"}}>
                                    <button className="btn btn-gold" onClick={() => navigate("/about")}><span className="btn-square"></span> Know More <i className="bi bi-arrow-up-right ms-2"></i></button>
                                </div>
                            </div> */}
                            
                            </div>
                        </div>
                        <div className="col-md-6 d-flex my-2">
                        <div className="flex-grow-1">
                            <img src={ourvalue} width={'100%'} alt="ourvalue" />
                        </div>
                        </div>
                    </div>
                    <div className="text-center" style={{display: isBlogData ? 'block' : 'none'}}>
                       <p className="home-news-title heading">Read Out Latest  News</p>
                    </div>
                    </div>
                </div>
                {/* news */}
                <div className="container my-4" style={{display: isBlogData ? 'block' : 'none'}}>
                    <BlogThumbnail requiredData={3} IsBlogData={setIsBlogData}/>
                    {/* <div className="text-center">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <img src={news1} alt="news1" width={'100%'}/>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <img src={news2} alt="news2" width={'100%'}/>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <img src={news1} alt="news1" width={'100%'}/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer/>
            <style>
        {`
                .slick-next {
                    right: 15px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #dba63a;
                }
                .slick-prev {
                    left: 15px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #dba63a;
                }
                `}
      </style>
        </div>
    )
}