import './Header.css';
import FaceBook from '../../assets/Icons/social_facebook.png';
import LinkedIn from '../../assets/Icons/social_linkedin.png';
import Youtube from '../../assets/Icons/social_youtube.png';
import Instagram from '../../assets/Icons/social_instagram.png';
import Twitter from '../../assets/Icons/social_twitter.png';
import Phone from '../../assets/Icons/004-phone-ringing.png';
import LocationIcon from '../../assets/Icons/079-map.png';
import Mail from '../../assets/Icons/002-email.png'

import { useState, useEffect } from 'react';

export default function Header() {

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="header-div">
      <div className="Header-Links my-2 mx-3">
        <a href="https://www.facebook.com/balcopvcpipes/" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={FaceBook} alt="FaceBook"/></a>
        <a href="https://www.linkedin.com/company/balcopipes" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={LinkedIn} alt="LinkedIn"/></a>
        <a href="https://www.youtube.com/channel/UC1yGWVTNMK46wn2M5GZPRrA" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={Youtube} alt="Youtube" /></a>
        <a href="https://www.instagram.com/balcopvcpipes/" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={Instagram} alt="Instagram" /></a>
        <a href="https://www.twitter.com/balcopvcpipes" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={Twitter} alt="Twitter" /></a>
        

        <div className='angle-div'></div>
        <div className='header-contant'>
          <div className='header-support-text'>
            <span style={{ display: 'flex', alignItems: "center" }}><img src={Phone} alt="Twitter" className='mx-2' /> . Get quick appointment and technical support: +91 62 35 35 00 00</span>
          </div>
          <div className='d-flex'>
            <div className='header-location-text' style={{ display: screenSize.width < 1315 && 'none' }}>
              <span style={{ display: 'flex', alignItems: "center" }}><img src={LocationIcon} alt="LocationIcon" className='mx-2' />Head Office BALCO Arcade, 3rd Floor Tholicode P O, Punalur</span>
            </div>
            <div className='header-email-text px-3' style={{ display: screenSize.width < 865 && 'none' }}>
              <span style={{ display: 'flex', alignItems: "center" }}><img src={Mail} alt="Mail" className='mx-2' />info@balcopipes.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}