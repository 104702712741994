import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';


export default function Investors (){


    return(
        <div>
            <Navbar/>
            <div>
                <div className="container py-5">
                    <div className="row">
                        {/* <div className="col-md-6">
                       
                        <div style={{width:"50px",borderBottom:"1px solid #004779"}}></div>
                            <p className="invertors-text text mt-3">BALCO Pipes, your trusted partner in high-quality electrical conduits and fittings. With a legacy of excellence spanning three decades, BALCO Pipes has achieved numerous milestones, cementing its position as a leader in the industry. Our dedication to quality has been recognized by prestigious organizations like BIS India, validating our commitment to delivering top-notch products. As we look to the future, we are excited to announce our plans to expand our presence not only across India but also in international markets.</p>
                            <p className="invertors-text text">Our active participation in various expos has also played a pivotal role in showcasing our innovative solutions and expanding our market presence. These expos have provided us with valuable opportunities to engage with industry leaders, showcase our products, and forge new partnerships. As we stand on the threshold of a new financial year, I am filled with optimism and enthusiasm about the opportunities that lie ahead. With strategic planning, innovation, and your unwavering dedication, I am confident that we will continue to scale new heights and set new benchmarks of success in the industry. Our goal is to bring our renowned expertise and premium solutions to customers worldwide, ensuring that they experience the reliability and innovation that define BALCO Pipes.</p>
                        </div> */}
                        {/* <div className="col-md-6">
                            <div>
                                {btnList.map((item, index)=>{
                                    return(
                                        <div key={index}>
                                            <button className="btn btn-gray w-100 text-start my-2" 
                                                onClick={() => {
                                                    const indexExists = openBtnDiv.includes(index);
                                                    setOpenBtnDiv(prevState => {
                                                        if (indexExists) {
                                                            return prevState.filter(item => item !== index);
                                                        } else {
                                                            return [...prevState, index];
                                                        }
                                                    });
                                                }}>
                                                    <i className={openBtnDiv.includes(index) ? 'bi bi-dash-square' : 'bi bi-plus-square'}></i> 
                                                    <span className="ms-5">{item.name}</span> 
                                            </button>
                                            {openBtnDiv.map((num) => {
                                                return(
                                                    num === index && 
                                                    <div className="d-flex" style={{justifyContent:"center", alignItems:"center"}}>
                                                        <button className="btn btn-gray d-flex my-1" style={{justifyContent:"space-between", alignItems:"center", width:"250px", backgroundColor:"#fff"}}>
                                                            <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Document <br />
                                                            <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <h2 className="invertors-title title">Message from Managing Director </h2>
                            <iframe width="100%" height="443px" src="https://www.youtube.com/embed/hOKEBwG7W6o?si=QE4NNFTkukZZPiXI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}