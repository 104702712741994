import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';
import { useState, useEffect } from "react";
import axios from "axios";

export default function Committee() {
    
    const [auditCommittee, setAuditCommittee] = useState([]);
    const [nomination, setNomination] = useState([]);
    const [stakeholders, setStakeholders] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-committees');
            const auditres = response.data.filter(item => item.committee_Type === "Audit Committee");
            const nominationres = response.data.filter(item => item.committee_Type === "Nomination and Remuneration Committee");
            const stakeholdersres = response.data.filter(item => item.committee_Type === "Stakeholders Relationship Committee");
            setAuditCommittee(auditres);
            setNomination(nominationres);
            setStakeholders(stakeholdersres);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    

    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                {/* Audit Committee */}
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Audit Committee</h2>
                    <div style={{overflowX:"auto"}}>
                    <table className="invertors-table">
                    <thead className="investor-thead mb-5">
                        <tr>
                        <th>Name of Director</th>
                        <th>Status in Committee </th>
                        <th>Nature of Directorship</th>
                        </tr>
                    </thead>
                    <tbody className="investor-tbody" style={{backgroundColor:"#fff"}}>
                        {auditCommittee.map((item, i) => {
                            return(
                            <tr key={i}>
                            <td>{item.name_Of_Director}</td>
                            <td>{item.status}</td>
                            <td>{item.nature_Of_Directorship}</td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                {/* Nomination and Remuneration Committee */}
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Nomination and Remuneration Committee</h2>
                    <div style={{overflowX:"auto"}}>
                    <table className="invertors-table">
                    <thead className="investor-thead mb-5">
                        <tr>
                        <th>Name of Director</th>
                        <th>Status in Committee </th>
                        <th>Nature of Directorship</th>
                        </tr>
                    </thead>
                    <tbody className="investor-tbody" style={{backgroundColor:"#fff"}}>
                        {nomination.map((item, i) => {
                            return(
                            <tr key={i}>
                            <td>{item.name_Of_Director}</td>
                            <td>{item.status}</td>
                            <td>{item.nature_Of_Directorship}</td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                {/* Stakeholders Relationship Committee */}
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Stakeholders Relationship Committee</h2>
                    <div style={{overflowX:"auto"}}>
                    <table className="invertors-table">
                    <thead className="investor-thead mb-5">
                        <tr>
                        <th>Name of Director</th>
                        <th>Status in Committee </th>
                        <th>Nature of Directorship</th>
                        </tr>
                    </thead>
                    <tbody className="investor-tbody" style={{backgroundColor:"#fff"}}>
                        {stakeholders.map((item, i) => {
                            return(
                            <tr key={i}>
                            <td>{item.name_Of_Director}</td>
                            <td>{item.status}</td>
                            <td>{item.nature_Of_Directorship}</td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}