import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Career.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// about silder images
import slider1 from "../../assets/Career slider/Career slider 1.png";
import slider2 from "../../assets/Career slider/Career slider 2.png";
import slider3 from "../../assets/Career slider/Career slider 3.png";

import photo1 from "../../assets/Images/Career photo 1.png";
import photo2 from "../../assets/Images/Career photo 2.png";
import { useState, useEffect } from "react";
import axios from "axios";


export default function Career() {

    const [formData, setFormData] = useState({
        position: '',
        experience: '',
        name: '',
        email: '',
        phone: '',
        resume: '',
        message: ''
      });
    
      const [errors, setErrors] = useState({
        position: '',
        experience: '',
        name: '',
        email: '',
        phone: '',
        resume: ''
      });
    
      const handleChange = (e) => {
        const { name, value, files } = e.target;
    
        if (files && files[0]) {
          const selectedFile = files[0];
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const content = event.target.result;
            setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: content,
            }));
          };
    
          reader.readAsText(selectedFile);
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
    
        // Clear the corresponding error when user starts typing
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
          isValid = false;
        }
    
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
        }
    
        if (!formData.phone.trim()) {
          newErrors.phone = 'Phone number is required';
          isValid = false;
        } else if (!/^\d{10}$/.test(formData.phone.trim())) {
          newErrors.phone = 'Invalid phone number (10 digits required)';
          isValid = false;
        }

        const file = formData.resume;
        if (!file) {
            newErrors.resume = 'Please select a resume.'
            isValid = false;
        } else if (file.size > 5242880) { // 5MB in bytes
            newErrors.resume = 'File size exceeds 5MB.'
            isValid = false;
        } 
    
        setErrors(newErrors);
        return isValid;
      };
    
      const handleSubmit = () => {    
        if (validateForm()) {
            const { name, email, phone, position, experience, resume, message } = formData;
            const mailtoLink = `mailto:Job@balcopipes.com?cc=${email}&subject=Contact Form Submission&body=Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0APosition: ${position}%0AExperience: ${experience} %0AMessage: ${message}%0AResume / CV: Please attach your resume manually`;
            window.location.href = mailtoLink;
        }
    };


    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-career-opening');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    

    const aboutSlider = [
        { image: slider1 },
        { image: slider2 },
        { image: slider3 },
    ]

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false
    };

    return (
        <div>
            <Navbar />
            <div>
                {/* career heading */}
                <div className="certified" >
                    <Slider {...settings}>
                        {aboutSlider.map((item, i) => {
                            return (
                                <div key={i}>
                                    <img src={item.image} style={{ width: "100%" }} alt={`about slider ${i}`} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                {/* career body */}
                <div className="container my-5">
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <h2 className="career-title title">Join Our Team at BALCO Pipes</h2>
                            <p className="career-subheading subheading">At BALCO Pipes, we prioritize innovation and excellence. We strive to develop cutting-edge products that meet our customers' evolving needs. With a focus on quality and technology, we maintain high standards and a commitment to continuous improvement. Our work culture encourages entrepreneurial thinking and adaptability, ensuring that we stay ahead in a dynamic market.</p>
                        </div>
                        <div className="col-md-6 my-2">
                            <img src={photo1} alt="photo1" width={"100%"} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <img src={photo2} alt="photo2" width={"100%"} />
                        </div>
                        <div className="col-md-6 my-2" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p className="career-subheading subheading">BALCO Pipes fosters a culture of trust and collaboration. We promote transparency and encourage employees to share ideas across departments. Our decentralized approach allows for quick implementation of innovative solutions, driving growth and success. We provide equal opportunities for personal and professional development, creating an environment where every individual can thrive.</p>
                        </div>
                    </div>
                </div>
                {/* career list content */}
                <div className="p-sm-5 career-transparent1">
                    <div className="p-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="">
                            <h2 className="career-title title">Why Choose BALCO Pipes?</h2>
                            <div>
                                <ul className="career-subheading subheading">
                                    <li>Opportunities for growth and career advancement</li>
                                    <li>A culture of innovation and excellence</li>
                                    <li>Collaborative work environment with transparent communication</li>
                                    <li>Access to cutting-edge technology and high-quality standards</li>
                                    <li>Equal opportunities for personal and professional development</li>
                                    <li>Emphasis on entrepreneurial thinking and adaptability</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* text */}
                <div className="container">
                    <div className="p-sm-5">
                        <h2 className="career-heading heading" style={{color:"#004779"}}>Join us at BALCO Pipes and be part of a team that values innovation, excellence, and continuous growth. Explore our current job openings and start your journey with us today.</h2>
                    </div>
                </div>
                {/* inputs */}
                <div className="certified py-5">
                    <div className="container">
                        <h4 className="text-center text-white career-title title">Current Opening</h4>
                        <div className="row">
                            {data.length > 0 && data.map((item, index) => {
                                 const { job_Roll, position, job_Location, education, experience } = item;
                                 const subject = encodeURIComponent(`Application for ${job_Roll} position`);
                                 const body = encodeURIComponent(`
                                     Your Name: 
                                     Your Email:
                                     Your Number:
                                     Your Position:
                                     Your Experience:
                                     Your Resume:
                             
                                     Job requirements:
                                     Job Role: ${job_Roll}
                                     Position: ${position}
                                     Location: ${job_Location}
                                     Education: ${education}
                                     Experience: ${experience}
                                 `);
                                 const mailtoLink = `mailto:Job@balcopipes.com?subject=${subject}&body=${body}`;

                                return(
                                    <div className="card col-md-4 d-flex flex-column justify-content-between p-2" style={{background:"#D9D9D9"}} key={index}>
                                        <div className="flex-grow-1" style={{margin:"0px 5px"}}>
                                            <h4 className="mb-3">{item.job_Roll}</h4>
                                            <div className="w-75">
                                                <div className="my-2 d-flex justify-content-between">
                                                    <p>{item.position}</p>
                                                    <span>|</span>
                                                    <p>{item.job_Location}</p>
                                                </div>
                                                <div className="my-2 d-flex justify-content-between">
                                                    <p><span style={{color: "#0061AF"}}>Education:</span><br />{item.education}</p>
                                                    <p><span style={{color: "#0061AF"}}>Experince:</span><br />{item.experience}</p>
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <a href={mailtoLink} className="btn btn-blue">Apply now</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="bg-white p-4 mt-5" style={{ backgroundColor: "#ffffff" }}>
                            <div className="row my-3">
                            <div className="col-md-6 my-2">
                                        <div className="form-group row">
                                            <label htmlFor="your-position-dealer" className="col-sm-5 col-form-label">Your Position:</label>
                                        <div className="col-sm-7">
                                        <input type="text" name="position" value={formData.position} onChange={handleChange} id="your-position-dealer" className="form-control"/>
                                            {errors.position && <span style={{ color: 'red' }} className="m-2">{errors.position}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-2">
                                        <div className="form-group row">
                                            <label htmlFor="your-experience-dealer" className="col-sm-5 col-form-label">Experience:</label>
                                        <div className="col-sm-7">
                                        <input type="text"  id="your-experience-dealer" name="experience" value={formData.experience} onChange={handleChange} className="form-control"/>
                                            {errors.experience && <span style={{ color: 'red' }} className="m-2">{errors.experience}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-md-6 my-2">
                                    <div className="form-group row">
                                        <label htmlFor="your-name-dealer" className="col-sm-5 col-form-label">Your Name:</label>
                                        <div className="col-sm-7">
                                            <input type="text" name="name" value={formData.name} onChange={handleChange} id="your-name-dealer" className="form-control"/>
                                            {errors.name && <span style={{ color: 'red' }} className="m-2">{errors.name}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-2">
                                    <div className="form-group row">
                                        <label htmlFor="your-email-dealer" className="col-sm-5 col-form-label">Your Email:</label>
                                        <div className="col-sm-7">
                                            <input type="email"  id="your-email-dealer" name="email" value={formData.email} onChange={handleChange} className="form-control"/>
                                            {errors.email && <span style={{ color: 'red' }} className="m-2">{errors.email}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-md-6 my-2">
                                    <div className="form-group row">
                                        <label htmlFor="your-number-dealer" className="col-sm-5 col-form-label">Your Number:</label>
                                        <div className="col-sm-7">
                                            <input id="your-number-dealer"  type="tel" name="phone" value={formData.phone} onChange={handleChange} className="form-control"/>
                                            {errors.phone && <span style={{ color: 'red' }} className="m-2">{errors.phone}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-2">
                                    <div className="form-group row">
                                        <label htmlFor="your-resume" className="col-sm-5 col-form-label">Resume / CV:</label>
                                        <div className="col-sm-7">
                                            <input id="your-resume"  type="file" name="resume" onChange={handleChange} className="form-control"/>
                                            {errors.resume && <span style={{ color: 'red' }} className="m-2">{errors.resume}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-12">
                                    <textarea className="form-control" rows="8" name="message" value={formData.message} onChange={handleChange} placeholder="Your Message:*"></textarea>
                                </div>
                            </div>
                            <div className="py-3 text-center">
                                <button className="btn btn-gold" style={{ color: "#004779" }} onClick={handleSubmit}><span className="btn-square"></span> Submit Now <i className="bi bi-arrow-up-right ms-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <style>
                {`
                .slick-next {
                    right: 15px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #dba63a;
                }
                .slick-prev {
                    left: 15px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #dba63a;
                }
                `}
            </style>
        </div>
    )
}