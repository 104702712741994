import Navbar from "./navbar/Navbar";
import Footer from "./navbar/Footer";

export default function PrivacyTerms() {

    return (
        <div>
            <Navbar />
            <div className="container py-5">
                <h2 className="pb-5">PRIVACY POLICIES</h2>
                    
                <h4>Cookies</h4>
                <p>
                    We employ the use of cookies. By accessing Balco Pipes, you agreed to use cookies
                    in agreement with the Solve Plastic Products Ltd’s Privacy Policy.
                    Most interactive websites use cookies to let us retrieve the user’s details for each
                    visit. Cookies are used by our website to enable the functionality of certain areas to
                    make it easier for people visiting our website. Some of our affiliate/advertising
                    partners may also use cookies.
                </p>

                <h4>License</h4>
                <p>
                    Unless otherwise stated,.Solve Plastic Products Ltd and/or its licensors own the
                    intellectual property rights for all material on Balco pipes. All intellectual property
                    rights are reserved. You may access this from Balco pipes for your own personal use
                    subjected to restrictions set in these terms and conditions.
                </p>
                <ul>
                    <li>Republish material from Balco pipes.</li>
                    <li>Sell, rent or sub-license material from Balco pipes.</li>
                    <li>Reproduce, duplicate or copy material from Balco pipes.</li>
                    <li>Redistribute content from Balco pipes.</li>
                </ul>
                <p>
                    Parts of this website offer an opportunity for users to post and exchange opinions
                    and information in certain areas of the website. Solve Plastic Products Ltd does not
                    filter, edit, publish or review Comments prior to their presence on the website.
                    Comments do not reflect the views and opinions of Solve Plastic Products Ltd , its
                    agents and/or affiliates. Comments reflect the views and opinions of the person who
                    post their views and opinions. To the extent permitted by applicable laws, Solve
                    Plastic Products Ltd . shall not be liable for the Comments or for any liability,
                    damages or expenses caused and/or suffered as a result of any use of and/or
                    posting of and/or appearance of the Comments on this website.
                    Solve Plastic Products Ltd reserves the right to monitor all Comments and to
                    remove any Comments which can be considered inappropriate, offensive or causes
                    breach of these Terms and Conditions.
                </p>

                <h4>Hyperlinking to our Content</h4>
                <p>The following organisations may link to our Website without prior written approval:</p>
                <ul>
                    <li>Government agencies;</li>
                    <li>Search engines;</li>
                    <li>News organisations;</li>
                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses;</li>
                    <li>Systemwide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website</li>
                </ul>

                <p>These organisations may link to our home page, to publications or to other Website information so long as the link.</p>
                <ol type="a">
                    <li>is not in any way deceptive;</li>
                    <li>does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services;</li>
                    <li>fits within the context of the linking party’s site.</li>
                </ol>

                <p>We may consider and approve other link requests from the following types of organisations:</p>

                <ul>
                    <li>Commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>associations or other groups representing charities;</li>
                    <li>online directory distributors;</li>
                    <li>internet portals;</li>
                    <li>accounting, law and consulting firms; and</li>
                    <li>educational institutions and trade associations.</li>
                </ul>

                <p>We will approve link requests from these organisations if we decide that:</p>
                <ol type="a">
                    <li>the link would not make us look unfavourably to ourselves or to our accredited businesses;</li>
                    <li>the organisation does not have any negative records with us;</li>
                    <li>the benefit to us from the visibility of the hyperlink compensates the absence of the link is in the context of general resource information.</li>
                </ol>
                
                <p>These organisations may link to our home page so long as the link:</p>
                <ol type="a">
                    <li>is not in any way deceptive;</li>
                    <li>does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services;</li>
                    <li>fits within the context of the linking party’s site.</li>
                </ol>

                <p>Approved organisations may hyperlink to our Website as follows:</p>
                <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                    <li>No use of Balco’s logo or other artwork will be allowed for linking absent a trademark licence agreement.</li>
                </ul>

                <h4>iFrames</h4>
                <p>
                Without prior approval and written permission, you may not create frames around our Web Pages that alter in any way the visual presentation or appearance of our Website.
                </p>

                <h4>Content Liability</h4>
                <p>
                We shall not be held responsible for any content that appears on your Website. You
                agree to protect and defend us against all claims that are rising on your Website. No
                link(s) should appear on any Website that may be interpreted as libellous, obscene
                or criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.
                </p>

                <h4>Your Privacy</h4>

                <p>
                When you visit our site <br />
                When you visit Balco pipes website, you are anonymous. We do not collect any personal information without your prior knowledge or consent.
                </p>

                <p>
                When you subscribe to our email lists
                Only with your consent are you added to our electronic mailing list. This consent is
                given by registering up via our sign up forms.
                We never share our email lists with any outside source unless it is a joint event with
                Balco or other websites. We respect your privacy and want to make it as convenient
                as possible to unsubscribe from our lists. You can unsubscribe at any point
                conveniently.
                </p>

                <h4>How do we handle your information?</h4>

                <p>
                The information you give to us via contact forms, account registration, newsletter
                subscription or emails is entered into the Balco pipes database so that we can
                process your request or order. When you create an account on our website, your
                information is stored on our servers so that it is available for your next visit.
                </p>

            </div>
            <Footer />

        </div>
    )
}