import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';

export default function Nodata() {

    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}} className="no-data">
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Coming Soon...</h2>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}