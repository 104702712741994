import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';
import pdfIcon from '../../assets/Icons/pdfIcon.png';
import { useState, useEffect } from "react";
import axios from 'axios';

export default function Policies() {
    
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-policies');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleDownload = (pdfUrl, fileName) => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Policies</h2>
                    <div>
                        <div className="investor-thead" style={{height:"50px", borderRadius:"31px"}}></div>
                        <div className="mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"15px"}}>
                            <div>
                                {data.length > 0 ? (
                                    data.map((item, index) => (
                                    <div
                                        className="row p-2 d-flex"
                                        style={{ justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}
                                        key={index}
                                    >
                                        <div className="col-md-7 col-lg-8 col-xl-9 text-start">
                                        {item.policie_Name}
                                        </div>
                                        <div className="col-md-5 col-lg-4 col-xl-3">
                                        <button
                                            className="btn btn-gold d-flex my-1 w-100"
                                            onClick={() => handleDownload("https://admin.balcopipes.in" + item.policy_Document, `${item.policie_Name}.pdf`)}
                                            style={{ justifyContent: "space-between", alignItems: "center" }}
                                        >
                                            <span className="contact-text text fw-bold" style={{ color: "#004779" }}>
                                            Download Document
                                            <br />
                                            <span className="contact-text small-text" style={{ color: "black" }}>
                                                Click here to download .PDF
                                            </span>
                                            </span>
                                            <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25} />
                                        </button>
                                        </div>
                                    </div>
                                    ))
                                ) : (
                                    <div>No data</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
