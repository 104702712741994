import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import PVCElectricImage from '../../assets/Images/ePVC fittings.png';
import PVCElectricFittingsImage from '../../assets/Images/fittings.png';
import pdfIcon from '../../assets/Icons/pdfIcon.png';
import elecProduct2 from "../../assets/Images/elec-product-2.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// circular box Photo
import circularbox1Photo1 from "../../assets/Products/elec pvc/Fittings/Circular JN box 1 way ISI/photo1.png";
import circularbox1Photo2 from "../../assets/Products/elec pvc/Fittings/Circular JN box 1 way ISI/photo2.png";
import circularbox1Photo3 from "../../assets/Products/elec pvc/Fittings/Circular JN box 1 way ISI/photo3.png";
import circularbox1Photo4 from "../../assets/Products/elec pvc/Fittings/Circular JN box 1 way ISI/photo4.png";
import circularbox2Photo1 from "../../assets/Products/elec pvc/Fittings/Circular JN box 2 way ISI/photo1.jpg";
import circularbox2Photo2 from "../../assets/Products/elec pvc/Fittings/Circular JN box 2 way ISI/photo2.jpg";
import circularbox2Photo3 from "../../assets/Products/elec pvc/Fittings/Circular JN box 2 way ISI/photo3.jpg";
import circularbox2Photo4 from "../../assets/Products/elec pvc/Fittings/Circular JN box 2 way ISI/photo4.jpg";
import circularbox3Photo1 from "../../assets/Products/elec pvc/Fittings/Circular JN box 3 way ISI/photo1.jpg";
import circularbox3Photo2 from "../../assets/Products/elec pvc/Fittings/Circular JN box 3 way ISI/photo2.jpg";
import circularbox3Photo3 from "../../assets/Products/elec pvc/Fittings/Circular JN box 3 way ISI/photo3.jpg";
import circularbox3Photo4 from "../../assets/Products/elec pvc/Fittings/Circular JN box 3 way ISI/photo4.jpg";
import circularbox4Photo1 from "../../assets/Products/elec pvc/Fittings/Circular JN box 4 way ISI/photo1.png";
import circularbox4Photo2 from "../../assets/Products/elec pvc/Fittings/Circular JN box 4 way ISI/photo2.png";
import circularbox4Photo3 from "../../assets/Products/elec pvc/Fittings/Circular JN box 4 way ISI/photo3.jpg";
import circularbox4Photo4 from "../../assets/Products/elec pvc/Fittings/Circular JN box 4 way ISI/photo4.jpg";
// deep circular box Photo
import deepcircularbox1Photo1 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 1 way ISI/photo1.png";
import deepcircularbox1Photo2 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 1 way ISI/photo2.png";
import deepcircularbox1Photo3 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 1 way ISI/photo3.png";
import deepcircularbox1Photo4 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 1 way ISI/photo4.png";
import deepcircularbox2Photo1 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 2 way ISI/photo1.jpg";
import deepcircularbox2Photo2 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 2 way ISI/photo2.jpg";
import deepcircularbox2Photo3 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 2 way ISI/photo3.jpg";
import deepcircularbox2Photo4 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 2 way ISI/photo4.jpg";
import deepcircularbox3Photo1 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 3 way ISI/photo1.jpg";
import deepcircularbox3Photo2 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 3 way ISI/photo2.jpg";
import deepcircularbox3Photo3 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 3 way ISI/photo3.jpg";
import deepcircularbox3Photo4 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 3 way ISI/photo4.jpg";
import deepcircularbox4Photo1 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 4 way ISI/photo1.jpg";
import deepcircularbox4Photo2 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 4 way ISI/photo2.jpg";
import deepcircularbox4Photo3 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 4 way ISI/photo3.png";
import deepcircularbox4Photo4 from "../../assets/Products/elec pvc/Fittings/Deep Circular JN box 4 way ISI/photo4.png";
// Slip type bend normal
import bendtypenormalphoto1 from '../../assets/Products/elec pvc/Fittings/Slip type bend normal/photo1.jpg';
import bendtypenormalphoto2 from '../../assets/Products/elec pvc/Fittings/Slip type bend normal/photo2.jpg';
import bendtypenormalphoto3 from '../../assets/Products/elec pvc/Fittings/Slip type bend normal/photo3.jpg';
import bendtypenormalphoto4 from '../../assets/Products/elec pvc/Fittings/Slip type bend normal/photo4.jpg';
// Slip type bend long
import bendtypelongphoto1 from '../../assets/Products/elec pvc/Fittings/Slip type bend long/photo1.jpg';
import bendtypelongphoto2 from '../../assets/Products/elec pvc/Fittings/Slip type bend long/photo2.jpg';
import bendtypelongphoto3 from '../../assets/Products/elec pvc/Fittings/Slip type bend long/photo3.jpg';
import bendtypelongphoto4 from '../../assets/Products/elec pvc/Fittings/Slip type bend long/photo4.jpg';
// PVC Fanhook JN boxHanger
import PVCBoxHangerphoto1 from '../../assets/Products/elec pvc/Fittings/PVC Fanhook JN box Hanger/photo1.png';
import PVCBoxHangerphoto2 from '../../assets/Products/elec pvc/Fittings/PVC Fanhook JN box Hanger/photo2.png';
import PVCBoxHangerphoto3 from '../../assets/Products/elec pvc/Fittings/PVC Fanhook JN box Hanger/photo3.png';
import PVCBoxHangerphoto4 from '../../assets/Products/elec pvc/Fittings/PVC Fanhook JN box Hanger/photo4.png';

import ElectricalBROCHURE from '../../assets/PDF folder/BALCO BROCHURE Electrical.pdf';
import CorporateBROCHURE from '../../assets/PDF folder/BALCO CORPORATE BROCHURE.pdf';

export default function PVCElectricFittings() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
    };

    const FittingsList = [
        {title: "CIRCULAR BOX TERMINAL (1 WAY) PVC", subtitle: "BALCO PVC JN BOX 1 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[circularbox1Photo1,circularbox1Photo2,circularbox1Photo3,circularbox1Photo4]},
        {title: "DEEP CIRCULAR BOX - TERMINAL 1 WAY PVC", subtitle: "BALCO PVC DEEP JN BOX 1 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["30", "25"], photos:[deepcircularbox1Photo1,deepcircularbox1Photo2,deepcircularbox1Photo3,deepcircularbox1Photo4]},
        {title: "CIRCULAR BOX - THROUGH 2 WAY PVC", subtitle: "BALCO PVC JN BOX 2 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[circularbox2Photo1,circularbox2Photo2,circularbox2Photo3,circularbox2Photo4]},
        {title: "DEEP CIRCULAR BOX - THROUGH 2 WAY PVC", subtitle: "BALCO PVC DEEP JN BOX 2 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["30", "25"], photos:[deepcircularbox2Photo1,deepcircularbox2Photo2,deepcircularbox2Photo3,deepcircularbox2Photo4]},
        {title: "CIRCULAR BOX - TEE 3 WAY PVC", subtitle: "BALCO PVC JN BOX 3 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[circularbox3Photo1,circularbox3Photo2,circularbox3Photo3,circularbox3Photo4]},
        {title: "DEEP CIRCULAR BOX - TEE 3 WAY PVC", subtitle: "BALCO PVC DEEP JN BOX 3 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["30", "25"], photos:[deepcircularbox3Photo1,deepcircularbox3Photo2,deepcircularbox3Photo3,deepcircularbox3Photo4]},
        {title: "CIRCULAR BOX - INTERSECTION 4 WAY PVC", subtitle: "BALCO PVC JN BOX 4 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[circularbox4Photo1,circularbox4Photo2,circularbox4Photo3,circularbox4Photo4]},
        {title: "DEEP CIRCULAR BOX - TEE 4 WAY PVC", subtitle: "BALCO PVC DEEP JN BOX 4 WAY ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["30", "25"], photos:[deepcircularbox4Photo1,deepcircularbox4Photo2,deepcircularbox4Photo3,deepcircularbox4Photo4]},
    ];

    const FittingsList2 = [
        {title: "SLIP TYPE BEND NORMAL", subtitle: "BALCO PVC BEND ECF   Light | Medium | Heavy", size: ["16 mm","20 mm", "25 mm", "32 mm", "40mm"], standardPack: ["500","500", "300", "300", "200"], photos:[bendtypenormalphoto1,bendtypenormalphoto2,bendtypenormalphoto3,bendtypenormalphoto4]},
        {title: "SLIP TYPE BEND LONG", subtitle: "BALCO PVC LONG BEND ECF/ISI", size: ["20 mm", "25 mm"], standardPack: ["250", "150"], photos:[bendtypelongphoto1,bendtypelongphoto2,bendtypelongphoto3,bendtypelongphoto4]},
        {title: "3/8  PVC FANHOOK JN BOX HANGER", subtitle: "BALCO PVC FANHOOK JN BOX HANGER ECF", size: ["20 mm"], standardPack: ["50"], photos:[PVCBoxHangerphoto1,PVCBoxHangerphoto2,PVCBoxHangerphoto3,PVCBoxHangerphoto4]},
        {title: "", subtitle: "BALCO PVC COUPLER ECF", size: ["16 mm","20 mm", "25 mm", "32 mm", "40mm"], standardPack: ["500", "2000", "1250", "1000", "600"], photos:[]},
        {title: "ROUND ELBOW", subtitle: "BALCO PP ELBOW ECF/I", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "ROUND TEE", subtitle: "BALCO PP TEE ECF", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "SADDLE STARP PVC", subtitle: "BALCO  SADDLE STRAP", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "SADDLE STARP METAL POWDER COATED", subtitle: "BALCO METAL SADDLE STRAP", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "SADDLE STARP METAL", subtitle: " BALCO METAL SADDLE STRAP", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        ];

    const FittingsList3 = [
        {title: "CIRCULAR BOX - ANGLE 2 WAY", subtitle: "BALCO SPEED PVC ANGLE JN BOX 2 WAY ECF", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "CIRCULAR BOX - H TYPE 4 WAY - BALCO Deluxe", subtitle: "BALCO DELUXE PP JN BOX H ECF", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "CIRCULAR BOX - U TYPE 2 WAY", subtitle: "CIRCULAR BOX - U TYPE 2 WAY", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "CIRCULAR BOX - Y TYPE 3 WAY", subtitle: "CIRCULAR BOX - Y TYPE 3 WAY", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        {title: "REDUCER COUPLER (PIPE TO PIPE)", subtitle: "REDUCER COUPLER (PIPE TO PIPE)", size: ["20 mm", "25 mm"], standardPack: ["50", "40"], photos:[]},
        ];

        const handleDownload1 = () => {
            const pdfUrl = ElectricalBROCHURE;
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'BALCO BROCHURE Electrical.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
    
          const handleDownload2 = () => {
            const pdfUrl = CorporateBROCHURE;
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'BALCO CORPORATE BROCHURE.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };

    return (
        <div>
            <Navbar />
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                        <div className="d-md-flex justify-content-center align-items-center py-5">
                            <div className="">
                                <h2 className="product-title title text-white">BALCO® Rigid Electric Conduits for Unparalleled Safety and Reliability in Every Installation Environment</h2>
                            </div>
                            <div className="w-lg-50">
                                <img src={PVCElectricImage} alt="PVCElectricImage" width={"100%"} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                    <div className="row my-4">
                        <div className="col-md-6">
                            <h2 className="product-heading heading">RIGID PVC ELECTRIC CONDUIT FITTINGS</h2>
                            <p className="product-text text">They are used in just about every residential and commercial establishment, especially for wiring that is either exposed, or fitted outdoors. Since they are essentially a safety feature, you should select the material, size and fittings based on the environment of the installation and the type of wiring it’ll be housing.</p>
                            <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Long lasting</li>
                                <li>Easy installation</li>
                                <li>Non-destructive in nature</li>
                                <li>Lightweight and easy to handle, introduce and transport</li>
                                <li>High mechanical quality for all applications</li>
                                <li>Smooth and adaptability for long span twisting.</li>
                            </ul>
                        </div>
                        <div className="col-md-6" style={{ backgroundColor: "#D9D9D9" }}>
                            <div className="m-4">
                                <img src={PVCElectricFittingsImage} alt="PVCElectricImage" width={"100%"} />
                            </div>
                        </div>
                    </div>
                    <div className="row my-4 text-center">
                        <div className="col-4 my-2">
                            <button className="btn btn-gray"><span className="p-4 product-subheading subheading">PWD</span></button>
                        </div>
                        <div className="col-4 my-2">
                            <button className="btn btn-gray"><span className="p-4 product-subheading subheading">CPWD</span></button>
                        </div>
                        <div className="col-4  my-2">
                            <button className="btn btn-gray"><span className="p-4 product-subheading subheading">MES</span></button>
                        </div>
                        {/* <div className="col-md-3 col-sm-6 my-2">
                            <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                        </div> */}
                    </div>
                    <div className="my-4">
                        <p className="product-text text">At BALCO, we understand the importance of choosing the right material for the right purpose when it comes to electrical fittings. We offer a range of different class products tailored to specific purposes, ensuring optimal performance and safety for your projects.</p>
                        <p className="product-text text">We strongly advise consulting with your electrician or project engineer to ensure that you are selecting the appropriate material for your intended use. This will help prevent any compatibility issues and ensure the longevity and reliability of your electrical installations.</p>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-4 d-flex">
                            <div className="p-4 flex-grow-1" style={{ background: "#F6F6F6", color: "#004779" }}>
                                <h4 className="product-heading heading">BALCO PVC ISI Fittings</h4>
                                <p className="product-text text">These are our premium ISI-certified PVC fittings, meeting the highest industry standards for quality and reliability.</p>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="p-4 flex-grow-1" style={{ background: "#F6F6F6", color: "#004779" }}>
                                <h4 className="product-heading heading">BALCO Speed Non-ISI PVC Fittings</h4>
                                <p className="product-text text">These fittings offer excellent performance and durability, designed for various plumbing applications where ISI certification is not mandatory.</p>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="p-4 flex-grow-1" style={{ background: "#F6F6F6", color: "#004779" }}>
                                <h4 className="product-heading heading">BALCO Deluxe PP Fittings</h4>
                                <p className="product-text text">Our Deluxe PP fittings are crafted with precision and innovation, providing superior strength and efficiency for your shorterm needs.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* product heading and color */}
                <div className="" style={{ background: "#004779" }}>
                    <div className="container text-center">
                        {/* <div className="row py-4">
                            <div className="col-lg-3 col-md-6"> 
                                <div style={{width:"200px", height:"200px", backgroundColor: "#D9D9D9", margin:"0px auto"}}></div> 
                                <p className="product-heading subheading text-center text-white">Fittings</p>
                            </div>
                            <div className="col-lg-3 col-md-6"> 
                                <div style={{width:"200px", height:"200px", backgroundColor: "#D9D9D9", margin:"0px auto"}}></div> 
                                <p className="product-heading subheading text-center text-white">Junction box</p>
                            </div>
                            <div className="col-lg-3 col-md-6"> 
                                <div style={{width:"200px", height:"200px", backgroundColor: "#D9D9D9", margin:"0px auto"}}></div> 
                                <p className="product-heading subheading text-center text-white">Bend</p>
                            </div>
                            <div className="col-lg-3 col-md-6"> 
                                <div style={{width:"200px", height:"200px", backgroundColor: "#D9D9D9", margin:"0px auto"}}></div> 
                                <p className="product-heading subheading text-center text-white">Bend</p>
                            </div>
                        </div> */}
                        <div className="m-5 mb-4 py-5">
                            <h2 className="product-title title text-white text-center" style={{ borderBottom: "2px solid white" }}>BALCO Fittings </h2>
                        </div>
                        {/* <p className="product-subheading subheading text-white text-center">Available color options</p>
                        <div className="d-flex flex-wrap" style={{justifyContent:"center"}}>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#FAF7E2'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#3076B6'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#656565'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#B3FFB1'}}></div>
                            </div>
                            <div className="flew-grow-1 mx-2">
                                <div style={{width:"50px", height:"50px", backgroundColor:'#9D2D2D'}}></div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* color section */}
                <div className="container my-5">
                    <div className="row">
                        <div className="col-8">
                            <h4 className="product-heading heading" style={{ color: "#004779" }}>COLOR</h4>
                            <ul className="product-subheading subheading" style={{ color: "#004779" }}>
                                <li>BALCO Conduits and fitting are available in two different colors.</li>
                                <li>We can also provide any color upon order. (terms and conditions apply)</li>
                            </ul>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <div className="w-100">
                                <div style={{ background: "#EEF0E5", width: "100%", height: "77px", border: "6px solid #FFFFFF", marginBottom: "10px" }}></div>
                                <div style={{ background: "#2F2F2F", width: "100%", height: "77px", border: "6px solid #FFFFFF" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* product items */}
                {/* <div className="container my-5">
                    <div className="row">
                        {new Array(12).fill(null).map((item, i) => {
                            return(
                                <div className="col-md-4 col-sm-6 my-3">
                                    <div style={{border:"2px solid #AEAEAE"}}>
                                        <div className="my-4" style={{width:"200px", height:"200px", margin:"0px auto"}}>
                                            {i === 0 && <img src={PVCProduct1} alt="PVCProduct1" width={'100%'}/>}
                                            {i === 1 && <img src={PVCProduct2} alt="PVCProduct2" width={'100%'}/>}
                                            {i === 2 && <img src={PVCProduct3} alt="PVCProduct3" width={'100%'}/>}
                                            {i === 3 && <img src={PVCProduct4} alt="PVCProduct4" width={'100%'}/>}
                                            {i === 4 && <img src={PVCProduct5} alt="PVCProduct5" width={'100%'}/>}
                                            {i === 5 && <img src={PVCProduct1} alt="PVCProduct1" width={'100%'}/>}
                                            {i === 6 && <img src={PVCProduct2} alt="PVCProduct2" width={'100%'}/>}
                                            {i === 7 && <img src={PVCProduct3} alt="PVCProduct3" width={'100%'}/>}
                                            {i === 8 && <img src={PVCProduct4} alt="PVCProduct4" width={'100%'}/>}
                                            {i === 9 && <img src={PVCProduct5} alt="PVCProduct5" width={'100%'}/>}
                                            {i === 10 && <img src={PVCProduct1} alt="PVCProduct1" width={'100%'}/>}
                                            {i === 11 && <img src={PVCProduct2} alt="PVCProduct2" width={'100%'}/>}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h2 className="product-heading heading text-center" >Fittings</h2>
                                        <p className="product-text text text-center" style={{lineHeight:"0.4"}}>20mm ISI Pipes green</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-6">
                            <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Non-conductor of electricity</li>
                                <li>High mechanical strength for covered and open applications</li>
                                <li>Non-destructive in nature and resistant to chemical and galvanic erosion.</li>
                                <li>First of its kind to have ISI certification in Kerala.</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4" >
                            <div className="">
                                <button className="btn btn-gold d-flex my-1 w-100" style={{justifyContent:"space-between", alignItems:"center"}}>
                                    <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Brochure <br />
                                    <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                    <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                </button>
                            </div>
                            <div className="">
                                <button className="btn btn-gold d-flex my-1 w-100" style={{justifyContent:"space-between", alignItems:"center"}}>
                                    <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download BALCO Brochure <br />
                                    <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                    <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="container my-5">
                    <div className="row m-0">
                        {FittingsList.map((item, index) => {
                            return(
                                <div className="col-lg-6 my-2" key={index}>
                                    <div className="" style={{ color: "white", backgroundColor: "#004779" }}>
                                        <h4 className="product-subheading subheading fw-bold text-center p-2">{item.title}</h4>
                                    </div>
                                    <div className="row me-0">
                                        <div className="col-8 my-2">
                                            <h5 className="product-text text text-center fw-bold mb-0" style={{ color: "#004779" }}>{item.subtitle}</h5>
                                            <div className="row">
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Size</div> </div>
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Standard Pack</div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><div className="product-scale-box">{item.size[0]}</div></div><div className="col-6"><div className="product-scale-box">{item.standardPack[0]}</div></div>
                                                <div className="col-6"><div className="product-scale-box">{item.size[1]}</div></div><div className="col-6"><div className="product-scale-box">{item.standardPack[1]}</div></div>
                                            </div>
                                        </div>
                                        <div className="col-4 my-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="w-100" style={{background: "#F3F3F3", padding: "5px",}}>
                                                <Slider {...settings}>
                                                    {item.photos.map((item, i) => {
                                                        return (
                                                                <img src={item} width={"100%"} alt={`home slider ${i}`} key={i}/>
                                                        )
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <hr className="black"/>
                    <div className="row m-0">
                        {FittingsList2.map((item, index) => {
                            return(
                                <div className="col-lg-6 my-2" key={index}>
                                    <div className="" style={{ color: "white", backgroundColor: "#004779" }}>
                                        <h4 className="product-subheading subheading fw-bold text-center p-2">{item.title}</h4>
                                    </div>
                                    <div className="row me-0">
                                        <div className="col-8 my-2">
                                            <h5 className="product-text text text-center fw-bold mb-0" style={{ color: "#004779" }}>{item.subtitle}</h5>
                                            <div className="row">
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Size</div> </div>
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Standard Pack</div></div>
                                            </div>
                                                {item.size.map((sizeItem, sizeIndex) => {
                                                    return(
                                                        <div className="row" key={sizeIndex}>
                                                        <div className="col-6"><div className="product-scale-box">{sizeItem}</div></div><div className="col-6"><div className="product-scale-box">{item.standardPack[sizeIndex]}</div></div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        {item.photos.length > 0 ?
                                        <div className="col-4 my-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="w-100" style={{background: "#F3F3F3", padding: "5px",}}>
                                                <Slider {...settings}>
                                                    {item.photos.map((item, i) => {
                                                        return (
                                                                <img src={item} width={"100%"} alt={`home slider ${i}`} key={i}/>
                                                        )
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-4 my-2" style={{ background: "#F3F3F3"}}></div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <hr className="black"/>
                    <div className="row m-0">
                        {FittingsList3.map((item, index) => {
                            return(
                                <div className="col-lg-6 my-2" key={index}>
                                    <div className="" style={{ color: "white", backgroundColor: "#004779" }}>
                                        <h4 className="product-subheading subheading fw-bold text-center p-2">{item.title}</h4>
                                    </div>
                                    <div className="row me-0">
                                        <div className="col-8 my-2">
                                            <h5 className="product-text text text-center fw-bold mb-0" style={{ color: "#004779" }}>{item.subtitle}</h5>
                                            <div className="row">
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Size</div> </div>
                                                <div className="col-6"> <div className="product-scale-box fw-bolder">Standard Pack</div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><div className="product-scale-box">{item.size[0]}</div></div><div className="col-6"><div className="product-scale-box">{item.standardPack[0]}</div></div>
                                                <div className="col-6"><div className="product-scale-box">{item.size[1]}</div></div><div className="col-6"><div className="product-scale-box">{item.standardPack[1]}</div></div>
                                            </div>
                                        </div>
                                        <div className="col-4 my-2" style={{ background: "#F3F3F3", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <hr className="black"/>
                </div>
                {/* last part */}
                <div className="py-5" style={{ backgroundColor: "#004779" }}>
                    <div className="container">
                        <div className="my-5">
                            <div className="row px-5">
                                <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload1} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Electrical Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div>
                                <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload2} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Corporate Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div>
                                {/* <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload3} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Water Pipes Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 text-white">
                                <p className="product-subheading subheading">Quality Analysis</p>
                                <ul className="product-text text text-white">
                                <li>Visual Examination</li>
                                <li>Checking of Dimensions</li>
                                <li>Resistance to Heat</li>
                                <li>Resistance to Burning</li>
                                <li>Electrical Characteristics</li>
                                <li>Moisture Absorption Test</li>
                                <li>Resistance to Chemical Action</li>
                                <li>Copper Test</li>
                                <li>Resistance to Oil</li>
                                <li>Resistance to Impact</li>
                                </ul>
                            </div>
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="" >
                                        <img src={elecProduct2} alt="elecProduct2" width={"100%"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <style>
        {`
                .slick-next {
                    right: 1px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #004779;
                }
                .slick-prev {
                    left: 1px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #004779;
                }
                `}
      </style>
        </div>
    )
}