import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import SolventImage from '../../assets/Images/solvent.png';
import ProductBanner from "./ProductBanners";


export default function Solvent () {
    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                    <div className="d-md-flex justify-content-center aline-item-center align-items-center py-5">
                        <div className="">
                            <h2 className="product-title title text-white">Fast & Eco-Friendly! The Ultimate PVC Pipe Joining Solution</h2>
                        </div>
                        <div className="">
                            <img src={SolventImage} alt="SolventImage" width={"100%"}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6">
                        <h2 className="product-heading heading">SOLVENT CEMENT</h2>
                        <p className="product-text text">Our Solvent cement is the most effective solution for joining PVC pipes, conduits and fittings. It is very fast and easy to use. In addition it has high joining strength and ensures watertight joints. It is available in specially designed eco-friendly tin containers.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="m-4" style={{backgroundColor: "#D9D9D9"}}>
                            <img src={SolventImage} alt="SolventImage" width={"100%"}/>
                        </div>
                    </div>
                </div>
                <hr className="black"/>
                <div className="my-4">
                <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>High quality</li>
                                <li>Reduced fumes and odour during installation</li>
                                <li>Meet the required standards for solvent cement used for plastic pipe installation.</li>
                                <li>Useful in piping applications like consumable water, turfs, water systems, pools and spas, fire assurance, conductor, and sewers.</li>
                            </ul>
                </div>
                </div>
                {/* product heading and color */}
                <ProductBanner/>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Marking</li>
                                <li>Checking of Dimensions</li>
                                <li>Resistance to Heat</li>
                                <li>Resistance to Burn</li>
                                <li>Moisture Absorption Test</li>
                                <li>Resistance to Chemical Action</li>
                                <li>Copper Test</li>
                                <li>Resistance to Oil</li>
                                <li>Resistance to Impact</li>
                                <li>Electrical Strength</li>
                                <li>Insulation Resistance</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div className="flex-grow-1">
                                    <img src={SolventImage} alt="allProductsImage" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}