import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import pvcpipesfitting from '../../assets/Images/pvc-water-fittings.png';
import pdfIcon from '../../assets/Icons/pdfIcon.png';

import allProductsImage from "../../assets/Images/elec-product-2.png";
import ProductBanner from "./ProductBanners";

import ElectricalBROCHURE from '../../assets/PDF folder/BALCO BROCHURE Electrical.pdf';
import CorporateBROCHURE from '../../assets/PDF folder/BALCO CORPORATE BROCHURE.pdf';
import WaterPipesBROCHURE from '../../assets/PDF folder/BALCO WATER PIPES BROCHURE.pdf';


// UPVC Water Pipe Fittings
import product1 from "../../assets/Products/upvc/UPVC water pipe fittings/UPVC water fitting 1.png";
import tee from "../../assets/Products/upvc/UPVC water pipe fittings/Tee.jpg";
import reducer from "../../assets/Products/upvc/UPVC water pipe fittings/Reducer.jpg";
import endCap from "../../assets/Products/upvc/UPVC water pipe fittings/End cap.jpg";
import elbow from "../../assets/Products/upvc/UPVC water pipe fittings/Elbow.jpg";
import coupler from "../../assets/Products/upvc/UPVC water pipe fittings/Coupler.jpg";

export default function PVCWaterFitting () {

    const handleDownload1 = () => {
        const pdfUrl = ElectricalBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO BROCHURE Electrical.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleDownload2 = () => {
        const pdfUrl = CorporateBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO CORPORATE BROCHURE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleDownload3 = () => {
        const pdfUrl = WaterPipesBROCHURE;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'BALCO WATER PIPES BROCHURE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const UPVCWaterPipeFitting = [
        {
            image: elbow, 
            title: "BALCO UPVC ELBOW", 
            data: [
                {size: "20MM 10KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
                {size: "75MM 6KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
                {size: "90MM 6KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
                {size: "110MM 6KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
                {size: "25MM 10KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
                {size: "32MM 10KG BALCO UPVC", standardPack: "ELBOW WP/ISI/LG"},
            ]
        },
        {
            image: tee, 
            title: "BALCO UPVC TEE", 
            data: [
                {size: "25MM 10KG BALCO UPVC", standardPack: "TEE WP/ISI/LG"},
                {size: "110MM 6KG BALCO UPVC", standardPack: "TEE WP/ISI/LG"},
                {size: "75MM 6KG BALCO UPVC", standardPack: "TEE WP/ISI/LG"},
                {size: "90MM 6KG BALCO UPVC", standardPack: "TEE WP/ISI/LG"},
                {size: "32MM 10KG BALCO UPVC", standardPack: "TEE WP/LG"},
            ]
        },
        {
            image: endCap, 
            title: "BALCO UPVC END CAP", 
            data: [
                {size: "32MM 10KG BALCO UPVC", standardPack: "ENDCAP WP/ISI/LG"},
                {size: "25MM 10KG BALCO UPVC", standardPack: "ENDCAP WP/ISI/LG"},
            ]
        },
        {
            image: coupler, 
            title: "BALCO UPVC COUPLER", 
            data: [
                {size: "32MM 10KG BALCO UPVC", standardPack: "COUPLER WP/ISI/LG"},
                {size: "25MM 10KG BALCO UPVC", standardPack: "COUPLER WP/ISI/LG"},
            ]
        },
        {
            image: reducer, 
            title: "BALCO UPVC REDUCER", 
            data: [
                {size: "32 X 25MM 10KG BALCO UPVC", standardPack: "REDUCER WP/ISI/LG"},
                {size: "25 X 20MM 10KG BALCO UPVC", standardPack: "REDUCER WP/ISI/LG"},
            ]
        },
    ];
    


    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div className="product-transparent">
                    <div className="container">
                    <div className="d-md-flex justify-content-center aline-item-center align-items-center py-5">
                        <div className="">
                            <h2 className="product-title title text-white">Discover Quality BALCO® Water Pipe Fittings</h2>
                        </div>
                        <div className="" style={{backgroundColor: "#D9D9D9", padding:"20px"}}>
                            <img src={pvcpipesfitting} alt="pvcpipesfitting" width={"100%"}/>
                        </div>
                    </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6">
                        <h2 className="product-heading heading">PVC Water Pipe Fittings</h2>
                        <p className="product-text text">They are used in just about every residential and commercial establishment, especially for wiring that is either exposed, or fitted outdoors. Since they are essentially a safety feature, you should select the material, size and fittings based on the environment of the installation and the type of wiring it’ll be housing.                        </p>
                        <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>Long lasting</li>
                                <li>Easy installation</li>
                                <li>Non-destructive in nature</li>
                                <li>Lightweight and easy to handle, introduce and transport</li>
                                <li>High mechanical quality for all applications</li>
                                <li>Smooth and adaptability for long span twisting.</li>
                            </ul>
                    </div>
                    <div className="col-md-6">
                        <div className="m-4">
                            <div style={{backgroundColor: "#D9D9D9", width:"100%", height:"443px"}}>
                                <img src={product1} alt="pvcwaterpipesfitting" width={"100%"} height={"443px"}/>    
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-4 text-center">
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                </div>
                </div>
                {/* product List */}
                <div style={{backgroundColor:"#004F87"}}>
                    <div className="container pt-5">
                        {UPVCWaterPipeFitting.map((item, i) => {
                                    return (
                                        <div className="py-4" key={i}>
                                            <div className=" my-2">
                                                <img src={item.image} alt={`pipe-fitting-${i}`} width={'100%'} />
                                            </div>
                                            <table className="product-table w-100">
                                                <thead>
                                                    <tr>
                                                        <td className="product-heading heading" style={{color: "#004F87", border:"5px solid black", backgroundColor:"white"}} colSpan="2">
                                                            {item.title}
                                                        </td>
                                                    </tr>
                                                    <tr className="product-subheading subheading fw-bold" style={{backgroundColor:"#004779", color:"white"}}>
                                                        <td>Size</td>
                                                        <td>Standard Pack</td>
                                                    </tr>
                                                </thead>
                                                <tbody className="product-text text fw-bold" style={{backgroundColor:"white", color:"#004779"}}>
                                                    {item.data.map((ditem, idx) => (
                                                        <tr key={idx}>
                                                            <td>{ditem.size}</td>
                                                            <td>{ditem.standardPack}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                    </div>
                </div>
                {/* product heading and color */}
                {/* <ProductBanner/> */}
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                    <div className="my-5">
                            <div className="row px-5">
                                <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload1} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Electrical Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div>
                                <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload2} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Corporate Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div>
                                <div className="col-md-4 ">
                                    <button className="btn btn-gold d-flex my-1 w-100" onClick={handleDownload3} style={{justifyContent:"space-between", alignItems:"center", borderRadius:"16px"}}>
                                        <span className="contact-text text fw-bold" style={{color:"#004779"}}>Download Water Pipes Brochure <br />
                                        <span className="contact-text small-text" style={{color:"black"}}>Click here to download .PDF</span></span>
                                        <img src={pdfIcon} alt="pdfIcon" className="ms-2" width={25}></img>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Dimensions</li>
                                <li>Opacity</li>
                                <li>Effect on Water</li>
                                <li>Stress Relief Test</li>
                                <li>Short-term</li>
                                <li>Hydraulic Test</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#D9D9D9"}}>
                                <div className="flex-grow-1">
                                    <img src={allProductsImage} alt="allProductsImage" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}